import { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import styles from './approved-quotations.module.css';
import AnsweredQuotationsIcon from '../../../../../../assets/icons/answered-quotations.svg';
import { useApprovedQuotations } from './hooks/useApprovedQuotations';
import { FilterComponent } from '../../../../../../shared/FilterComponent';
import { useFilteredAnswers } from '../../hooks/useFilteredAnswers';
import { GetMyAnswerFilters } from '../../../autoshop/interface';
import { ViewAnswerQuotationModal } from '../../../workshop/components/ViewAnswerQuotationModal';
import { AnswerQuotation } from '../../../../../../@types/interface';
import { AnswerList } from './components/QuotationListItem';
import useConfirmModal from '../../hooks/useConfirmModal';
import useSelectedAnswer from '../../hooks/useSelectedAnswer';
import { useAnswerQuotation } from '../../../../../../contexts/answerQuotationContext';

export const ApprovedQuotations = () => {
  const {
    isLoading,
    loadMoreData,
    page,
    handleRowClick,
    fetchQuotationAnswerById,
  } = useApprovedQuotations();
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    filterType,
    filterValue,
    handleDateSearch,
    handleSearch,
    searchedAnswers,
    setFilterType,
  } = useFilteredAnswers({
    option: 'not_confirmed',
  });

  const { answerQuotationState } = useAnswerQuotation();

  const confirmModal = useConfirmModal();
  const selectedAnswer = useSelectedAnswer();

  const handleGetQuotationByIdParam = useCallback(async () => {
    const answerId = searchParams.get('id');
    if (answerId) {
      await fetchQuotationAnswerById(answerId);
      setSearchParams({});
      confirmModal.onOpen();
    }
  }, [confirmModal, fetchQuotationAnswerById, searchParams, setSearchParams]);

  useEffect(() => {
    handleGetQuotationByIdParam();
  }, [handleGetQuotationByIdParam]);

  const isEmpty = useMemo(
    () => !isLoading && answerQuotationState.pendingQuotationList.length === 0,
    [answerQuotationState.pendingQuotationList.length, isLoading],
  );
  const notFound = useMemo(
    () => filterValue.trim().length > 0 && searchedAnswers.length === 0,
    [filterValue, searchedAnswers.length],
  );
  return (
    <>
      <ViewAnswerQuotationModal
        answer={selectedAnswer.answer}
        isOpen={confirmModal.isOpen}
        onClose={() => {
          selectedAnswer.onClear();
          confirmModal.onClose();
        }}
        onUpdate={() => console.log('')}
        isConfirming
      />
      <div className={styles.heading}>
        <div className={styles.describe}>
          <img src={AnsweredQuotationsIcon} alt="responder cotações" />
          <p>Pedidos Aguardando Confirmação</p>
        </div>
        <div className={styles.actions}>
          <FilterComponent
            handleDateSearch={handleDateSearch}
            handleSearch={handleSearch}
            handleFilterChange={(filter: string) =>
              setFilterType(filter as GetMyAnswerFilters)
            }
            defaultValue="code"
            variant={filterType === 'date_recieved' ? 'date' : 'searchbar'}
            onlyNumber={filterType === 'code'}
            options={[
              {
                label: 'Número',
                value: 'code',
              },
              {
                label: 'Data',
                value: 'date_recieved',
              },
              {
                label: 'Placa',
                value: 'plate',
              },
              {
                label: 'Origem',
                value: 'origin',
              },
            ]}
          />
        </div>
      </div>
      <AnswerList
        answerList={
          filterValue.trim().length > 0
            ? searchedAnswers
            : answerQuotationState.pendingQuotationList
        }
        isLoading={isLoading}
        isEmpty={isEmpty}
        notFound={notFound}
        onScroll={() => loadMoreData(page)}
        handleRowClick={(answer: AnswerQuotation) => handleRowClick(answer)}
      />
    </>
  );
};
