import { handleError } from '../../../../utils/handleError';
import { ChangeIsWatchingStatusInput } from './interface';
import { changeIsWatchingStatus, isQuotationBeingWatch } from './model';

export const changeIsWatchingStatusController = async (
  props: ChangeIsWatchingStatusInput,
) => {
  try {
    const res = await changeIsWatchingStatus({ ...props });
    return res;
  } catch (err) {
    handleError(err);
  }
};

export const IsQuotationBeingWatchController = async (id: string) => {
  try {
    const res = await isQuotationBeingWatch(id);
    return res;
  } catch (err) {
    handleError(err);
  }
};
