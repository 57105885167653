/* eslint-disable no-nested-ternary */
import React from 'react';
import { CaretDown } from '@phosphor-icons/react';
import { Repair } from '../../../../common/History/interfaces';
import Tooltip from '../../../../../shared/ToolTip';
import { dateOffset, formatDate } from '../../../../../utils/dateFunctions';
import { maskPhone } from '../../../../../services/helpers/mask';
import styles from './repair-item.module.css';

interface Props {
  repair: Repair;
}

export const RepairItem = ({ repair }: Props) => {
  const handleText = (text: string) => {
    return (
      <span
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        {text.length > 15 ? `${text.slice(0, 15)}...` : text}
      </span>
    );
  };

  let date = dateOffset(new Date(repair.created_at));

  if (repair.finish_date && repair.finish_time) {
    date = dateOffset(new Date(`${repair.finish_date}T${repair.finish_time}`));
  }

  return (
    <>
      <div className={`${styles.desktop} ${styles.dropdown}`}>
        <div className={styles.icon}>
          <CaretDown size="1.5rem" />
        </div>
        <span className={styles['text-left']}>
          {repair?.external_id || repair.OS}
        </span>
      </div>
      <div className={styles['first-mobile']}>
        <div className={styles.icon}>
          <CaretDown size="1.5rem" />
        </div>
        <Tooltip
          text={
            repair.name
              ? repair.name
              : repair?.user_workshop?.user?.name || 'Cliente Avulso'
          }
        >
          {handleText(
            repair.name
              ? repair.name
              : repair?.user_workshop?.user?.name || 'Cliente Avulso',
          )}
        </Tooltip>
      </div>
      <div>{repair.vehicle?.license_plate || '----'}</div>
      <div className={styles.desktop}>
        <Tooltip text={repair.vehicle?.model || 'Outros'}>
          {handleText(repair.vehicle?.model || 'Outros')}
        </Tooltip>
      </div>
      <div className={`${styles.desktop}`}>
        {repair.phone
          ? maskPhone(repair.phone)
          : repair?.user_workshop?.user?.phone
          ? maskPhone(repair.user_workshop.user.phone)
          : 'não informado'}
      </div>
      <div className={styles.last}>
        <span>{formatDate(date)}</span>{' '}
        <div
          className={`${styles['status-indicator']} ${
            repair.finished ? styles.finished : ''
          }`}
        />
      </div>
    </>
  );
};
