/* eslint-disable react/no-array-index-key */
import {
  ChangeEvent,
  useRef,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';
import { Plus, Minus, PlusCircle } from 'phosphor-react';
import pixel from '../../styles/size';
import { Preview } from './components/Preview';
import { ImageModal } from './components/ImageModal';
import { resizeImage } from '../../utils/files/resizeImage';
import { ChecklistInput } from '../../@types/interface';
import { COLORS } from '../../styles/colors';
import defaultImage from '../../assets/icons/sendImage.svg';
import styles from './checklist-form.module.css';

interface ChecklistFormProps {
  isOpen: boolean;
  toggleIsOpen: () => void;
  checklistData: ChecklistInput[];
  setChecklistData: Dispatch<SetStateAction<ChecklistInput[]>>;
  bannersToRemove: string[];
  setBannersToRemove: Dispatch<SetStateAction<string[]>>;
  uploadErrors: number[];
}

interface SelectImageProps extends ChecklistInput {
  index?: number;
}

export const ChecklistForm = ({
  isOpen,
  toggleIsOpen,
  bannersToRemove,
  setBannersToRemove,
  checklistData,
  setChecklistData,
  uploadErrors,
}: ChecklistFormProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [showSendModal, setShowSendModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState<SelectImageProps | null>(
    null,
  );

  const handleRemove = async (image: SelectImageProps) => {
    const filteredImages = checklistData.filter(
      (_, index) => index !== image.index,
    );
    const bannerToRemove =
      checklistData.find((_, index) => index === image.index)?.id_banner || '';
    setChecklistData(filteredImages);
    if (bannerToRemove.length > 0) {
      setBannersToRemove([...bannersToRemove, bannerToRemove]);
    }
  };

  // LÓGICA
  const handleSelection = (index: number) => {
    setSelectedImage({ ...checklistData[index], index });
    setShowRemoveModal(true);
  };

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const newSelectedFiles = event.target.files;
    if (newSelectedFiles) {
      let file = newSelectedFiles[0];

      const id_banner = '';

      file = await resizeImage(file);

      const img_url = URL.createObjectURL(file);
      const newImage = { file, img_url, id_banner };
      setSelectedImage(newImage);

      setShowSendModal(true);
      inputRef.current!.value = '';
    }
  };

  useEffect(() => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: true }).catch(error => {
        console.error('Erro ao acessar a câmera:', error);
      });
    } else {
      console.error('getUserMedia não é suportado neste navegador.');
    }
  }, []);

  return (
    <div className={styles.container}>
      <ImageModal
        title="Enviar Imagem"
        isOpen={showSendModal}
        url={selectedImage?.img_url || ''}
        onClose={() => {
          setShowSendModal(false);
        }}
        onConfirm={() => {
          setShowSendModal(false);
          if (selectedImage && selectedImage.file) {
            setChecklistData([...checklistData, selectedImage]);
          }
        }}
        confirmText="Confirmar"
      />
      <ImageModal
        title="Visualizar Imagem"
        isOpen={showRemoveModal}
        url={selectedImage?.img_url || ''}
        onClose={() => {
          setShowRemoveModal(false);
        }}
        onConfirm={() => {
          if (selectedImage) {
            handleRemove(selectedImage);
          }
          setShowRemoveModal(false);
        }}
        confirmText="Remover"
      />
      <ul className={styles.imagesList}>
        <input
          type="file"
          id="user"
          onChange={handleFileChange}
          capture="user"
          accept="image/*"
          ref={inputRef}
          hidden
        />
        {checklistData.map((item, index) => (
          <Item
            key={item.id_banner}
            image={item}
            index={index}
            handleSelection={handleSelection}
            inputRef={inputRef}
            errorOcurred={uploadErrors.includes(index)}
          />
        ))}
        {checklistData.length < 10 && (
          <button
            type="button"
            className={styles.addImageBtn}
            onClick={() => inputRef.current?.click()}
          >
            <PlusCircle size={48} />
            adicionar imagem
          </button>
        )}
      </ul>
    </div>
  );

  // return (
  //   <Box mx=".5rem">
  //     <ImageModal
  //       title="Enviar Imagem"
  //       isOpen={showSendModal}
  //       url={selectedImage?.img_url || ''}
  //       onClose={() => {
  //         setShowSendModal(false);
  //       }}
  //       onConfirm={() => {
  //         setShowSendModal(false);
  //         if (selectedImage && selectedImage.file) {
  //           setChecklistData([...checklistData, selectedImage]);
  //         }
  //       }}
  //       confirmText="Confirmar"
  //     />
  //     <ImageModal
  //       title="Visualizar Imagem"
  //       isOpen={showRemoveModal}
  //       url={selectedImage?.img_url || ''}
  //       onClose={() => {
  //         setShowRemoveModal(false);
  //       }}
  //       onConfirm={() => {
  //         if (selectedImage) {
  //           handleRemove(selectedImage);
  //         }
  //         setShowRemoveModal(false);
  //       }}
  //       confirmText="Remover"
  //     />
  //     <Row justifyContent="space-between" alignItems="center">
  //       <Text color={COLORS.DARKGRAY} fontWeight="600" px=".4rem" mr="1rem">
  //         Checklist
  //       </Text>
  //       {!isOpen ? (
  //         <Pressable onPress={toggleIsOpen}>
  //           <View
  //             flexDirection="row"
  //             bg={COLORS.GREEN}
  //             justifyContent="space-between"
  //             alignItems="center"
  //             px=".4rem"
  //             py=".5rem"
  //             borderRadius="8px"
  //             borderBottomRightRadius="20px"
  //             borderTopRightRadius="20px"
  //           >
  //             <Text color={COLORS.WHITE} fontWeight="600" mr=".5rem">
  //               Adicionar imagem
  //             </Text>
  //             <Plus size="1.5rem" weight="bold" color={COLORS.WHITE} />
  //           </View>
  //         </Pressable>
  //       ) : (
  //         <Pressable onPress={toggleIsOpen}>
  //           <View
  //             flexDirection="row"
  //             bg="#D96C6C"
  //             justifyContent="space-between"
  //             alignItems="center"
  //             px=".4rem"
  //             py=".5rem"
  //             borderRadius="8px"
  //             borderBottomRightRadius="20px"
  //             borderTopRightRadius="20px"
  //           >
  //             <Text color={COLORS.WHITE} fontWeight="600" mr=".5rem">
  //               Cancelar
  //             </Text>
  //             <Minus size="1.5rem" weight="bold" color={COLORS.WHITE} />
  //           </View>
  //         </Pressable>
  //       )}
  //     </Row>
  //     {isOpen && (
  //       <Box>
  //         <input
  //           type="file"
  //           id="user"
  //           onChange={handleFileChange}
  //           capture="user"
  //           accept="image/*"
  //           ref={inputRef}
  //           hidden
  //         />
  //         <Row
  //           justifyContent="space-between"
  //           flexWrap="wrap"
  //           w={{ base: pixel(830, 1080), xl: pixel(730, 1920) }}
  //         >
  //           {[...Array(3)].map((_, index) => (
  //             <Preview
  //               key={index}
  //               image={
  //                 index < checklistData.length ? checklistData[index] : null
  //               }
  //               index={index}
  //               handleSelection={handleSelection}
  //               inputRef={inputRef}
  //               errorOcurred={uploadErrors.includes(index)}
  //             />
  //           ))}
  //         </Row>
  //       </Box>
  //     )}
  //   </Box>
  // );
};

interface ItemProps {
  index: number;
  handleSelection: (index: number) => void;
  inputRef: React.RefObject<HTMLInputElement>;
  image: ChecklistInput | null;
  isLoading?: boolean;
  errorOcurred?: boolean;
  errorMessage?: string;
}

const Item = ({
  index,
  handleSelection,
  inputRef,
  image,
  isLoading = false,
  errorOcurred = false,
  errorMessage = 'Ocorreu um erro',
}: ItemProps) => {
  const imgRef = useRef<HTMLImageElement>(null);
  useEffect(() => {
    const currentImgRef = imgRef.current;
    const currentInputRef = inputRef.current;
    const handleImageClick = () => {
      if (image) handleSelection(index);
      else currentInputRef?.click();
    };
    if (currentImgRef) {
      currentImgRef.addEventListener('click', handleImageClick);
    }

    return () => {
      if (currentImgRef) {
        currentImgRef.removeEventListener('click', handleImageClick);
      }
    };
  }, []);
  return (
    <li>
      <img
        ref={imgRef}
        src={image?.img_url || defaultImage}
        alt="selecione uma imagem"
      />
    </li>
  );
};
