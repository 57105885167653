import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { Typography } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Container } from '@mui/system';
import pixel from '../../../../../styles/size';

interface AutocompleteProps {
  value: string | null;
  options: string[];
  setValue: (arg: string) => void;
  setFormName: (arg: string) => void;
  setShowTransferVehicle: React.Dispatch<React.SetStateAction<boolean>>;
}
// TODO: usar styledComponent
export const InputAutocomplete = ({
  value,
  options,
  setValue,
  setFormName,
  setShowTransferVehicle,
}: AutocompleteProps) => {
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '9px',
        alignItems: 'start',
        width: [pixel(830, 1080), '100%', pixel(730, 1920)],
        padding: '0 !important',
        marginBottom: '.5rem',
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{
          fontSize: '1rem',
          color: '#737373',
          fontWeight: 500,
          lineHeight: '1.5rem',
          display: 'flex',
        }}
        display="block"
        gutterBottom
      >
        Nome:<sup style={{ color: '#E00D0D', fontWeight: 'Semibold' }}>*</sup>
      </Typography>
      <Autocomplete
        sx={{
          border: '1px solid #d4d4d4',
          borderRadius: '9px',
          display: 'inline-block',
          boxSizing: 'border-box',
          width: '100%',
          margin: 'auto',
          '& input': {
            width: '100%',
            borderRadius: '9px',
            height: '3rem',
            border: 'none',
            padding: '0.5rem 0.75rem',
          },
        }}
        id="custom-input-demo"
        options={options}
        freeSolo
        value={value || ''}
        renderInput={params => (
          <div ref={params.InputProps.ref}>
            <input
              type="text"
              value={value || ''}
              {...params.inputProps}
              style={{
                fontSize: '1rem',
              }}
            />
          </div>
        )}
        onChange={(event: any, newValue: string | null) => {
          setValue(newValue || '');
          setShowTransferVehicle(true);
        }}
        onInputChange={(event: any, newValue: string | null) => {
          setValue(newValue || '');
          setFormName(newValue || '');
        }}
      />
    </Container>
  );
};
