import { BrowserRouter } from 'react-router-dom';
import { Suspense } from 'react';
import AuthProvider from '../hooks/useAuth';
import { PrivateRoutes } from './private/routes';
import { PublicRoutes } from './public/routes';
import Fallback from '../shared/Fallback/Fallback';

export const AppRoutes = () => {
  return (
    <BrowserRouter>
      <PublicRoutes />
      <Suspense fallback={<Fallback />}>
        <AuthProvider>
          <PrivateRoutes />
        </AuthProvider>
      </Suspense>
    </BrowserRouter>
  );
};
