import { Eye, WhatsappLogo } from 'phosphor-react';
import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect } from 'react';
import {
  Content,
  MainRow,
  Row,
  Body,
  BoldText,
  ScrollView,
} from './_ReadModal';
import { NotificationProps } from '../../model';
import { sendWhatsappMessage } from '../../../../../utils/sendWhatsappMessage';
import { handlePreventiveMessage } from '../NotificationCard';
import { Modal } from '../../../../../shared/Modal';
import { Button } from '../../../../../shared/Button';
import { useAuth } from '../../../../../hooks/useAuth';
import { handleReadNotification } from '../../controller';

interface ConfirmModalProps {
  isOpen: boolean;
  notification: NotificationProps | null;
  onClose: () => void;
}

const handleNotificationTitle = (
  notificationType: string,
  userName: string,
) => {
  switch (notificationType) {
    case 'master':
      return 'Autocenter Informa';
    case 'assessment':
      return `Avaliação de ${userName}`;
    default:
      return `Manutenção Preventiva de ${userName}`;
  }
};

const handleNotificationOrigin = (notificationType: string) => {
  switch (notificationType) {
    case 'master':
      return 'Aviso';
    case 'assessment':
      return 'Avaliação';
    default:
      return 'Manutenção Preventiva';
  }
};

export const ReadModal = ({
  isOpen,
  onClose,
  notification,
}: ConfirmModalProps) => {
  const navigate = useNavigate();
  const { workshop } = useAuth();

  const markNotificationAsRead = useCallback(async () => {
    await handleReadNotification(notification?.id_notification ?? '');
  }, [notification?.id_notification]);

  useEffect(() => {
    if (isOpen && notification) {
      markNotificationAsRead();
    }
  }, [isOpen, markNotificationAsRead, notification]);

  if (notification) {
    const { message, type, user, updated_at, preventive, assessment_id } =
      notification;
    const title = handleNotificationTitle(type, user?.name);
    // type === 'master'
    //   ? 'Autocenter Informa'
    //   : `Manutenção Preventiva de ${user?.name}`;

    const formatDate = (inDate: Date) => {
      const date = new Date(inDate).toLocaleString().split('/');
      const year = new Date(inDate).getFullYear().toString().slice(2, 4);
      return `${date[0].length > 1 ? date[0] : `0${date[0]}`}/${
        date[1].length > 1 ? date[1] : `0${date[1]}`
      }/${year}`;
    };

    const date = formatDate(updated_at);

    const renderWhatsapp = () => {
      if (type === 'master') {
        return (
          <Button
            icon={<WhatsappLogo size={20} />}
            handleClick={() =>
              sendWhatsappMessage(
                `Olá! Autocenter. Gostaria de ajuda sobre ${message} recebida.`,
                '+558791080605',
              )
            }
          >
            Entrar em Contato
          </Button>
        );
      }

      return (
        <Button
          icon={<WhatsappLogo size={20} />}
          handleClick={() =>
            sendWhatsappMessage(
              handlePreventiveMessage(user, preventive, workshop),
              user.phone,
            )
          }
        >
          Entrar em Contato
        </Button>
      );
    };

    const renderButtonNavigateToAssessment = () => {
      if (
        type !== 'assessment' ||
        assessment_id === null ||
        assessment_id === ''
      ) {
        return;
      }

      return (
        <Button
          icon={<Eye size={20} />}
          handleClick={() => {
            navigate(`/assessment?id=${assessment_id}`);
          }}
        >
          Visualizar
        </Button>
      );
    };

    return (
      <Modal isOpen={isOpen} onClose={onClose} title={title}>
        <Body>
          <MainRow>
            <Row>
              <BoldText> Origem: </BoldText>
              <div>
                {
                  handleNotificationOrigin(type)
                  // type === 'master' ? 'Aviso' : 'Manutenção Preventiva'
                }
              </div>
            </Row>
            <Row>
              <BoldText> Data: </BoldText>
              <div>{date}</div>
            </Row>
          </MainRow>

          <ScrollView height={window.innerHeight * 0.3}>
            <Content> {message} </Content>
          </ScrollView>

          <MainRow>
            {renderWhatsapp()}
            {renderButtonNavigateToAssessment()}
          </MainRow>
        </Body>
      </Modal>
    );
  }

  return null;
};
