import { useEffect, useState } from 'react';
import { Modal } from '../../../PreventiveMaintenance/components/Modal';
import { deleteOrderServiceController } from '../../controller';
import styles from './delete-modal.module.css';
import { Repair } from '../../../../common/History/interfaces';

interface Props {
  repair: Repair | null;
  onClose: () => void;
  onDelete: () => void;
}

export const DeleteModal = ({ repair, onClose, onDelete }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (repair) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [repair]);

  const handleDeleteRepair = async () => {
    if (repair) {
      await deleteOrderServiceController(repair.id_repair);
      onDelete();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Excluir OS">
      <div className={styles.container}>
        <h3>
          A OS: <b>{repair?.external_id || repair?.OS || ''}</b> será excluida
        </h3>

        <div className={styles.actions}>
          <button type="button" className={styles.cancel} onClick={onClose}>
            Cancelar
          </button>
          <button
            type="button"
            className={styles.exclude}
            onClick={() => {
              handleDeleteRepair();
              onClose();
            }}
          >
            Excluir
          </button>
        </div>
      </div>
    </Modal>
  );
};
