import { QuotationNotificationResponse } from '../pages/common/QuotationNofication/interface';

export const getNotificationLink = (
  newNotification: QuotationNotificationResponse | null,
): string => {
  if (!newNotification) return '';
  if (!newNotification.event) return '';

  switch (newNotification.event) {
    case 'quotation_recieved':
      return `/answer-quotation?page=notAnswered&id=${newNotification.answer_quotation_id}`;
    case 'answer_quotation_confirmed':
      return `/new-quotation?page=completed&id=${newNotification?.answer_quotation?.id_answer_quotation}`;
    case 'quotation_answered':
      return `/new-quotation?page=replies&id=${newNotification.quotation_id}`;
    case 'answer_approved':
      return `/answer-quotation?page=approved&id=${newNotification.answer_quotation_id}`;
    case 'answer_rejected':
      return `/answer-quotation?page=finished&id=${newNotification.answer_quotation_id}`;
    case 'answer_quotation_reject':
      return `/answer-quotation?page=finished&id=${newNotification.quotation_id}`;
    default:
      return '/home';
  }
};
