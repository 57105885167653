import { Route, Routes as Switch } from 'react-router-dom';
import { QuotationNotification } from '../../pages/common/QuotationNofication';
import PrivateRoute from '../private/routesPrivate';
import { AgentManagement } from '../../pages/common/AgentManagement';
import { Register } from '../../pages/common/Register';
import { WorkshopQuotation } from '../../pages/common/Quotation/workshop';
import { AutoshopQuotation } from '../../pages/common/Quotation/autoshop';
import { AnswerQuotationProvider } from '../../contexts/answerQuotationContext';
import { QuotationProvider } from '../../contexts/quotationContext';
import { CreateOrderService } from '../../pages/common/OrderServiceCreate';
import { BudgetCreate } from '../../pages/common/BudgetCreate';
import { Notifications } from '../../pages/common/Notifications/Notifications';
import LoginLandingPage from '../../pages/common/LandingPage/Pages/Login/Login';
import { Budget } from '../../pages/common/Budget';

export const CommonRoutes = () => {
  return (
    <Switch>
      <Route path="/page-login" element={<LoginLandingPage />} />
      <Route
        path="/notifications"
        element={
          <PrivateRoute>
            <Notifications />
          </PrivateRoute>
        }
      />
      <Route
        path="/new-quotation"
        element={
          <PrivateRoute>
            <QuotationProvider>
              <WorkshopQuotation />
            </QuotationProvider>
          </PrivateRoute>
        }
      />
      <Route
        path="/budget"
        element={
          <PrivateRoute>
            <Budget />
          </PrivateRoute>
        }
      />
      <Route
        path="/answer-quotation"
        element={
          <PrivateRoute>
            <AnswerQuotationProvider>
              <AutoshopQuotation />
            </AnswerQuotationProvider>
          </PrivateRoute>
        }
      />
      <Route
        path="/quotation-notification"
        element={
          <PrivateRoute>
            <QuotationNotification />
          </PrivateRoute>
        }
      />
      <Route
        path="/agent-management"
        element={
          <PrivateRoute>
            <AgentManagement />
          </PrivateRoute>
        }
      />
      <Route
        path="/register"
        element={
          <PrivateRoute>
            <Register />
          </PrivateRoute>
        }
      />
      <Route
        path="/create-budget"
        element={
          <PrivateRoute>
            <BudgetCreate />
          </PrivateRoute>
        }
      />
      <Route
        path="/create-order-service/:id_budget"
        element={
          <PrivateRoute>
            <CreateOrderService />
          </PrivateRoute>
        }
      />
      <Route
        path="/create-order-service"
        element={
          <PrivateRoute>
            <CreateOrderService />
          </PrivateRoute>
        }
      />
      <Route
        path="/create-budget"
        element={
          <PrivateRoute>
            <BudgetCreate />
          </PrivateRoute>
        }
      />
    </Switch>
  );
};
