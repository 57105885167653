import { handleError } from '../../../utils/handleError';
import {
  GetOrderServiceInput,
  deleteOrderService,
  finishOrderService,
  getOrderService,
  getOrderServiceByLicensePlate,
} from './model';

export const getOrderServiceController = async ({
  limit,
  page,
  query = '',
}: GetOrderServiceInput) => {
  try {
    const params: GetOrderServiceInput = {};
    if (limit) params.limit = limit;
    if (page) params.page = page;
    if (query) params.query = query;

    const res = await getOrderService({ limit, page, query });
    return res;
  } catch (error) {
    handleError(error);
  }
};
export const getOrderServiceByLicensePlateController = async (
  licensePlate: string,
) => {
  try {
    const formattedLicensePlate = licensePlate
      .replace('[^A-Z0-9]', '')
      .trim()
      .toUpperCase();
    const res = await getOrderServiceByLicensePlate(formattedLicensePlate);
    return res;
  } catch (error) {
    handleError(error);
  }
};

export const deleteOrderServiceController = async (orderServiceId: string) => {
  try {
    const res = await deleteOrderService(orderServiceId);
    return res;
  } catch (error) {
    handleError(error);
  }
};

export const finishOrderServiceController = async (orderServiceId: string) => {
  try {
    const res = await finishOrderService(orderServiceId);
    return res;
  } catch (error) {
    handleError(error);
  }
};
