import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { MainComponent } from '../../../shared/MainComponent/MainComponent';
import { CustomerForm } from './components/forms/CustomerForm';
import { ServiceForm } from './components/forms/ServiceForm';
import styles from './budget-create.module.css';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { getBudgetByIdController } from './controller';
import { setBudget } from '../../../features/budget/budget-slice';

export const BudgetCreate = () => {
  const [page, setPage] = useState(0);
  const budget = useAppSelector(state => state.budget.budget);
  const dispatch = useDispatch();

  const fetchBudgetData = useCallback(
    async (id: string) => {
      const res = await getBudgetByIdController(id);
      if (res) {
        dispatch(setBudget(res));
      }
    },
    [dispatch],
  );

  const budgetId = useMemo(() => budget?.id_budget ?? '', [budget?.id_budget]);

  useEffect(() => {
    if (budgetId.length > 0) {
      fetchBudgetData(budgetId);
    }
  }, [budgetId, fetchBudgetData]);

  const formPagination = (currentForm: number) => {
    switch (currentForm) {
      case 0:
        return <CustomerForm handleClick={nextPage} page={page} />;
      default:
        return <ServiceForm page={page} setPage={setPage} />;
    }
  };
  const nextPage = useCallback(() => {
    setPage(page + 1);
  }, [page]);

  return (
    <div className={styles.container}>
      <MainComponent>{formPagination(page)}</MainComponent>
    </div>
  );
};
