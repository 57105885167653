import React, { useEffect, useState, useRef } from 'react';
import { X } from '@phosphor-icons/react';
import { useDebounce } from 'use-debounce';
import styles from './filter-component.module.css';
import { FilterMenu, FilterMenuOptions } from '../FilterMenu';
import { Input } from '../Input';
import { Button } from '../Button';

interface Props {
  variant: 'searchbar' | 'date';
  defaultValue?: string;
  options: FilterMenuOptions[];
  handleSearch: (query: string) => void;
  handleDateSearch: (period: string[]) => void;
  handleFilterChange: (filter: string) => void;
  upperCase?: boolean;
  onlyNumber?: boolean;
  isLoading?: boolean;
}

export const FilterComponent = ({
  options,
  variant,
  defaultValue = '',
  handleSearch,
  handleDateSearch,
  handleFilterChange,
  upperCase = false,
  onlyNumber = false,
  isLoading = false,
}: Props) => {
  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [filterName, setFilterName] = useState(
    options.find(option => option.value === defaultValue)?.label || '',
  );
  const isInitialMount = useRef(true);
  // Debounce the search query
  const [debouncedSearchQuery] = useDebounce(searchQuery, 300);

  const handleDateChange = (value: string) => {
    const date = new Date(value || '').toISOString().split('T')[0];
    return date;
  };

  const handleClear = (type: 'search' | 'date' | 'all') => {
    if (type === 'search' || type === 'all') {
      handleSearch('');
      setSearchQuery('');
    }
    if (type === 'date' || type === 'all') {
      handleDateSearch([]);
      setInitialDate('');
      setFinalDate('');
    }
  };

  const handleFilter = (value: string) => {
    handleFilterChange(value);
    setFilterName(options.find(option => option.value === value)?.label || '');
    handleClear('all');
  };

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    if (initialDate && finalDate) {
      handleDateSearch([initialDate, finalDate]);
    }
  }, [initialDate, finalDate, handleDateSearch]);

  useEffect(() => {
    if (
      initialDate &&
      finalDate &&
      new Date(initialDate) > new Date(finalDate)
    ) {
      alert('A data inicial não pode ser maior que a data final');
      setInitialDate('');
      setFinalDate('');
    }
  }, [initialDate, finalDate]);

  // Effect to trigger handleSearch only after debounce
  useEffect(() => {
    handleSearch(debouncedSearchQuery);
  }, [debouncedSearchQuery, handleSearch]);

  return (
    <div className={styles['filter-container']}>
      <div className={styles.filters}>
        {variant === 'searchbar' && (
          <Input
            name="search"
            placeholder={`Pesquisar ${filterName}`}
            type="search"
            className={styles['search-input']}
            value={searchQuery}
            handleChange={e => {
              let newValue = e.target.value;
              if (upperCase) newValue = newValue.toUpperCase();
              if (onlyNumber) newValue = newValue.replace(/\D/g, '');

              setSearchQuery(newValue);
            }}
            isLoading={isLoading}
          />
        )}
        {variant === 'date' && (
          <div className={styles['date-container']}>
            <Input
              type="date"
              name="initialDate"
              handleChange={e =>
                setInitialDate(handleDateChange(e.target.value))
              }
              value={initialDate}
            />
            <Input
              type="date"
              name="finalDate"
              handleChange={e => setFinalDate(handleDateChange(e.target.value))}
              value={finalDate}
            />
          </div>
        )}
        <FilterMenu
          options={options}
          defaultValue={defaultValue}
          handleChange={handleFilter}
        />
      </div>
      {(initialDate.trim().length > 0 ||
        finalDate.trim().length > 0 ||
        searchQuery.trim().length > 0) && (
        <Button
          handleClick={() => handleClear('all')}
          variant="unstyled"
          className={styles['filter-button']}
        >
          <X size={20} />
          limpar busca
        </Button>
      )}
    </div>
  );
};
