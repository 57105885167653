import { IAddress } from '../../../../../../@types/interface';
import api from '../../../../../../services/api/api';
import { CreateCustomerResponse } from '../ServicesForm/model';
import { CustomersResponse } from './interfaces';

export interface NewAddressInput {
  zip: string;
  street: string;
  uf: string;
  city: string;
  district: string;
  number: string;
}
export interface UpdateAddressInput extends NewAddressInput {
  addressId: string;
}

export interface CreateCustomerInput {
  name: string;
  document: string;
  email?: string;
  phone?: string;
  address?: IAddress;
  // workshop_id: string;
}

export const createAddress = async (newAddress: NewAddressInput) => {
  const { data } = await api.post<IAddress>('/address', newAddress);
  return data;
};
export const updateAddress = async (newAddress: UpdateAddressInput) => {
  const { data } = await api.put<IAddress>('/address', newAddress);
  return data;
};
export const findAddressByIs = async (addressId: string) => {
  const { data } = await api.get<IAddress>(`/address/${addressId}`);
  return data;
};
export const findCustomersByWorkshopId = async (workshopId: string) => {
  const { data } = await api.get<CustomersResponse[]>('/workshop/customer', {
    params: { limit: 1000 },
  });
  return data;
};
export const createCustomer = async (customerData: CreateCustomerInput) => {
  const { data } = await api.post<CreateCustomerResponse>(
    '/workshop/customer',
    {
      vehicles: [],
      ...customerData,
    },
  );
  return data;
};
