import { Modal } from '../../../../../shared/Modal';
import { Button } from '../../../../../shared/Button';
import styles from './delete-modal.module.css';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
}

export const DeleteModal = ({ isOpen, onClose, onDelete }: Props) => {
  return (
    <Modal
      title="Tem Certeza?"
      isOpen={isOpen}
      onClose={onClose}
      footer={
        <div className={styles.actions}>
          <Button handleClick={onClose} variant="register">
            Cancelar
          </Button>
          <Button handleClick={onDelete} theme="delete" variant="register">
            Excluir
          </Button>
        </div>
      }
    >
      <span>
        <b>Deseja excluir está notificação?</b>
      </span>
    </Modal>
  );
};
