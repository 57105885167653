import { handleError } from '../../../utils/handleError';
import { getBudgetById } from './model';

export const getBudgetByIdController = async (id: string) => {
  try {
    const res = await getBudgetById(id);
    return res;
  } catch (error) {
    handleError(error);
  }
};
