import { useCallback, useEffect, useMemo, useState } from 'react';
import Lottie from 'lottie-react';
import { StepsController } from '../../../../../../../../shared/StepsController';
import { Button } from '../../../../../../../../shared/Button';
import { Items } from './steps/Items';
import { Companies } from './steps/Companies';
import styles from './create-quotation.module.css';
import creatingAnimation from '../../../../../../../../assets/roda.json';
import errorAnimation from '../../../../../../../../assets/errorLottie.json';
import { useCreateQuotation } from './useCreateQuotation';
import { WorkshopList, useSendQuotation } from './useSendQuotation';
import { useQuotationForm } from './useQuotationForm';
import { AlertMessage } from '../../../../../../../../shared/AlertMessage';
import {
  ModalContent,
  ModalTitle,
} from '../../../../../../Finance/components/Modal';
import {
  getQuotationByIdController,
  transformDraftInQuotationController,
  updateQuotationDraftController,
} from '../../../../controller';
import useSelectedQuotation from '../../../../hooks/useSelectedQuotation';
import useCreateQuotationModal from '../../../../hooks/useCreateQuotationModal';
import { Loading } from '../../../../../../../../shared/Loading';
import { useQuotation } from '../../../../../../../../contexts/quotationContext';
import useFeedbackModal from '../../../../hooks/useFeedbackModal';
import useRedoModal from '../../../../hooks/useRedoModal';
import { Quotation } from '../../../../../../../../@types/interface';

interface Props {
  title: string;
  isRedoing: boolean;
  previousQuotationId: string;
  onRemoveQuotation: (id: string) => void;
  onRedoQuotation: () => void;
  onSaveDraft?: () => void;
}

export const CreateQuotationModal = ({
  title,
  onRemoveQuotation,
  isRedoing,
  previousQuotationId,
  onRedoQuotation,
  onSaveDraft,
}: Props) => {
  const [step, setStep] = useState<
    'items' | 'companies' | 'creating' | 'sending' | 'success' | 'error'
  >('items');
  const [alertMessage, setAlertMessage] = useState('');
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [previousQuotation, setPreviousQuotation] = useState<Quotation | null>(
    null,
  );

  const { createQuotation } = useCreateQuotation();
  const { sendQuotation } = useSendQuotation();
  const selectedQuotation = useSelectedQuotation();
  const createModal = useCreateQuotationModal();
  const redoModal = useRedoModal();

  const {
    control,
    getValues,
    imagesList,
    resetFormValues,
    handleAddRow,
    handleVehicleFields,
    quotes,
    errors,
    remove,
    trigger,
    paymentTypes,
    checkIsFormEmpty,
    setImagesUrls,
    imagesUrls,
  } = useQuotationForm({
    isRedoing,
    previousQuotation,
  });

  const feedbackModal = useFeedbackModal();

  const [isDraftModalOpen, setIsDraftModalOpen] = useState(false);
  const [workshopList, setWorkshopList] = useState<WorkshopList[]>([]);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const selectedWorkshops = useMemo(
    () => workshopList.filter(workshop => workshop.selected),
    [workshopList],
  );

  const { setQuotationState, updateQuotations } = useQuotation();

  const currentDraftId = useMemo(() => {
    return selectedQuotation.quotation?.id_quotation ?? '';
  }, [selectedQuotation.quotation?.id_quotation]);

  const handleUpdateDraft = useCallback(async () => {
    const { quotes: items, comment, plate, paymentMethod } = getValues();
    const res = await updateQuotationDraftController({
      vehicle_plate: plate || '',
      comment,
      quotation_id: currentDraftId,
      type_payment: paymentMethod,
      images_urls: imagesUrls,
      items,
    });

    if (res) {
      setQuotationState(previous => ({
        ...previous,
        notAnsweredQuotationList: previous.notAnsweredQuotationList.map(
          quotation => {
            if (quotation.id_quotation === res.id_quotation) return res;
            return quotation;
          },
        ),
      }));
    }
  }, [currentDraftId, getValues, imagesUrls, setQuotationState]);

  const handleStep = useMemo(() => {
    if (step === 'items') {
      return (
        <Items
          control={control}
          handleAddRow={handleAddRow}
          quotes={quotes}
          remove={remove}
          imagesUrls={imagesUrls}
          setImagesUrls={setImagesUrls}
          handleVehicleFields={handleVehicleFields}
          paymentTypes={paymentTypes}
        />
      );
    }
    if (step === 'companies') {
      return (
        <Companies
          setWorkshopList={setWorkshopList}
          workshopList={workshopList}
          onSend={() => {
            setIsConfirmModalOpen(true);
          }}
          onReturn={() => setStep('items')}
        />
      );
    }
    if (step === 'creating') {
      return (
        <div style={{ width: '300px', height: '300px', margin: 'auto' }}>
          <Lottie animationData={creatingAnimation} />
        </div>
      );
    }
    if (step === 'sending') {
      return (
        <div style={{ width: '300px', height: '300px', margin: 'auto' }}>
          <Lottie animationData={creatingAnimation} />
        </div>
      );
    }
    if (step === 'success') {
      return (
        <div style={{ width: '300px', height: '300px', margin: 'auto' }}>
          <Lottie animationData={creatingAnimation} loop={false} />
        </div>
      );
    }
    if (step === 'error') {
      return (
        <div style={{ width: '300px', height: '300px', margin: 'auto' }}>
          <Lottie animationData={errorAnimation} />
        </div>
      );
    }
  }, [
    control,
    handleAddRow,
    handleVehicleFields,
    imagesUrls,
    paymentTypes,
    quotes,
    remove,
    setImagesUrls,
    step,
    workshopList,
  ]);

  const handleCreateQuotation = useCallback(async () => {
    const { quotes: items, vehicleId, comment, paymentMethod } = getValues();
    const res = await createQuotation({
      items,
      vehicleId,
      comment: comment ?? '',
      imagesList,
      type_payment: paymentMethod,
    });
    if (res) {
      return res;
    }
    setStep('error');
  }, [createQuotation, getValues, imagesList]);

  const handleClose = useCallback(() => {
    document.body.style.overflow = 'auto';
    setStep('items');
    resetFormValues();
    createModal.onClose();
    selectedQuotation.onClear();
    redoModal.onClose();
  }, [
    createModal.onClose,
    resetFormValues,
    selectedQuotation.onClear,
    redoModal.onClose,
  ]);

  const handleSendQuotation = useCallback(
    async (quotationId: string) => {
      const res = await sendQuotation({
        quotationId,
        workshopList,
      });
      if (res) {
        setStep('success');
        handleClose();
        if (isRedoing) {
          await onRedoQuotation();
        }
      } else {
        setStep('error');
      }
    },
    [handleClose, sendQuotation, workshopList, isRedoing],
  );

  const onSubmit = useCallback(async () => {
    if (currentDraftId.length > 0) {
      setStep('creating');
      const res = await transformDraftInQuotationController(currentDraftId);
      if (res) {
        updateQuotations(res);
        setStep('sending');
        await handleSendQuotation(res.id_quotation);
      }
    } else {
      setStep('creating');
      const res = await handleCreateQuotation();
      if (res) {
        setStep('sending');
        await handleSendQuotation(res.id_quotation);
      }
    }
  }, [
    currentDraftId,
    handleCreateQuotation,
    handleSendQuotation,
    updateQuotations,
  ]);

  const handleModalTitle = useMemo(() => {
    if (step === 'items' || step === 'companies') return title;
    if (step === 'sending') return 'Enviando Cotação';
    if (step === 'creating') return 'Criando Cotação';
    if (step === 'success') return 'Cotação Criada';
    return 'Falha ao criar cotação';
  }, [step, title]);

  const changeStep = useCallback(async () => {
    const isValid = await trigger();
    if (isValid) {
      try {
        feedbackModal.onOpen();
        await handleUpdateDraft();
        setStep('companies');
      } catch (error) {
        console.error(error);
      } finally {
        feedbackModal.onClose();
      }
    } else if (errors.quotes) {
      setAlertMessage(
        'Verifique os itens da cotação, ela não pode ser uma cotação vazia de itens!',
      );
    }
  }, [
    errors.quotes,
    handleUpdateDraft,
    trigger,
    feedbackModal.onOpen,
    feedbackModal.onClose,
  ]);

  const nextStepButton = useMemo(() => {
    if (step === 'items') {
      return (
        <div className={styles.wrapButtonFooter}>
          <Button
            variant="register"
            style={{
              padding: '.5rem 2.5rem',
            }}
            handleClick={changeStep}
            id="next-step-button"
          >
            Próximo
          </Button>
        </div>
      );
    }

    return null;

    // return (
    //   <div className={styles.wrapButtonFooter}>
    //     {selectedWorkshops.length > 0 ? (
    //       <Button
    //         variant="register"
    //         style={{
    //           padding: '.5rem 2.5rem',
    //         }}
    //         handleClick={() => {
    //           setIsConfirmModalOpen(true);
    //         }}
    //       >
    //         Enviar
    //       </Button>
    //     ) : null}
    //   </div>
    // );
  }, [changeStep, selectedWorkshops.length, step]);

  const showButton = useMemo(() => {
    if (isLoadingData) return false;
    return step !== 'items' && step !== 'companies';
  }, [isLoadingData, step]);

  const handleRemoveDraft = useCallback(() => {
    onRemoveQuotation(selectedQuotation.quotation?.id_quotation ?? '');
    setIsDraftModalOpen(false);
    handleClose();
  }, [
    handleClose,
    onRemoveQuotation,
    selectedQuotation.quotation?.id_quotation,
  ]);

  const handleCloseSaveModal = useCallback(async () => {
    setIsDraftModalOpen(false);
  }, []);

  const handleCloseSavingDraft = useCallback(async () => {
    try {
      setIsDraftModalOpen(false);
      feedbackModal.onOpen();
      await handleUpdateDraft();
      if (onSaveDraft) onSaveDraft();
      handleClose();
    } catch (error) {
      console.error(error);
    } finally {
      feedbackModal.onClose();
    }
  }, [
    handleClose,
    handleUpdateDraft,
    feedbackModal.onOpen,
    feedbackModal.onClose,
  ]);

  useEffect(() => {
    const fetchQuotation = async () => {
      const id = isRedoing
        ? previousQuotationId
        : selectedQuotation.quotation?.id_quotation ?? '';

      try {
        setIsLoadingData(true);
        const res = await getQuotationByIdController(id);

        if (res) {
          const answersQuotation = res?.answers_quotation ?? [];
          const workshopsOptions = answersQuotation.map(answer => ({
            selected: true,
            option: {
              name: answer.workshop.fantasy_name,
              value: answer.workshop.id_workshop,
            },
          }));

          console.log('opções anteriores: ', workshopsOptions);

          setPreviousQuotation(res);
          setWorkshopList(workshopsOptions);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoadingData(false);
      }
    };

    if (createModal.isOpen) {
      fetchQuotation();
    }
  }, [
    isRedoing,
    previousQuotationId,
    selectedQuotation.quotation?.id_quotation,
    createModal.isOpen,
  ]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (createModal.isOpen && currentDraftId.length > 0 && step === 'items') {
        handleUpdateDraft();
      }
    }, 1000 * 3);

    return () => {
      clearInterval(intervalId);
    };
  }, [
    selectedQuotation.quotation,
    handleUpdateDraft,
    createModal.isOpen,
    currentDraftId.length,
    step,
  ]);

  return (
    <ModalContent
      open={createModal.isOpen}
      onClose={async () => {
        if (checkIsFormEmpty()) {
          await handleRemoveDraft();
        } else setIsDraftModalOpen(true);
      }}
      hasLogo
      borderTop
      full
      ContentClassName={styles.modal}
    >
      <ModalTitle>{handleModalTitle}</ModalTitle>
      {(step === 'items' || step === 'companies') && (
        <StepsController.Root
          style={{
            flex: 1,
            width: '100%',
          }}
        >
          <StepsController.Item
            label="1. Itens"
            selected={step === 'items'}
            onClick={() => setStep('items')}
          />
          <StepsController.Item
            label="2. Fornecedores"
            selected={step === 'companies'}
            onClick={() => changeStep()}
          />
        </StepsController.Root>
      )}

      <AlertMessageModal
        alertMessage={alertMessage}
        onClose={() => setAlertMessage('')}
      />

      <ConfirmQuotationModal
        isOpen={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        onConfirm={() => {
          setIsConfirmModalOpen(false);
          onSubmit();
        }}
      />

      <SaveDraftModal
        isOpen={isDraftModalOpen}
        onClose={handleCloseSaveModal}
        onRemove={handleRemoveDraft}
        onConfirm={handleCloseSavingDraft}
      />

      {isLoadingData ? <Loader /> : handleStep}
      {showButton ? null : nextStepButton}
    </ModalContent>
  );
};

const Loader = () => {
  return (
    <>
      <Loading /> <Loading /> <Loading /> <Loading />
      <Loading />
    </>
  );
};

interface SaveDraftModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onRemove: () => void;
}

const SaveDraftModal = ({
  isOpen,
  onClose,
  onConfirm,
  onRemove,
}: SaveDraftModalProps) => {
  return (
    <AlertMessage.Root
      isOpen={isOpen}
      onClose={onClose}
      title="Deseja salvar Rascunho?"
      footer={
        <AlertMessage.Footer>
          <AlertMessage.CancelButton onClick={onRemove} id="remove-draft-btn">
            Descartar
          </AlertMessage.CancelButton>
          <AlertMessage.ConfirmButton onClick={onConfirm} id="save-draft-btn">
            Salvar
          </AlertMessage.ConfirmButton>
        </AlertMessage.Footer>
      }
    />
  );
};

interface ConfirmQuotationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmQuotationModal = ({
  isOpen,
  onClose,
  onConfirm,
}: ConfirmQuotationModalProps) => {
  return (
    <AlertMessage.Root
      isOpen={isOpen}
      onClose={onClose}
      title="Confirmar Envio?"
      footer={
        <AlertMessage.Footer>
          <AlertMessage.CancelButton onClick={onClose} id="review-draft-btn">
            Revisar
          </AlertMessage.CancelButton>
          <AlertMessage.ConfirmButton
            onClick={onConfirm}
            id="save-draft-and-continue-btn"
          >
            Enviar Cotação
          </AlertMessage.ConfirmButton>
        </AlertMessage.Footer>
      }
    >
      <AlertMessage.Body>
        Tem certeza que deseja enviar a cotação?
      </AlertMessage.Body>
    </AlertMessage.Root>
  );
};

interface AlertMessageModalProps {
  alertMessage: string;
  onClose: () => void;
}

const AlertMessageModal = ({
  alertMessage,
  onClose,
}: AlertMessageModalProps) => {
  return (
    <AlertMessage.Root isOpen={alertMessage.length !== 0} onClose={onClose}>
      <AlertMessage.Body>{alertMessage}</AlertMessage.Body>
    </AlertMessage.Root>
  );
};
