import { VehicleResponse } from '../../services/dataFetchers/VehicleDataFetcher/model';

export const formatVehicle = (data: VehicleResponse) => {
  const { name, displacement, fuel } = data;

  const items = [];

  if (name) {
    items.push(name);
  }
  if (displacement) {
    items.push(`${displacement}cc`);
  }
  if (fuel) {
    items.push(fuel);
  }

  if (items.length > 0) {
    return items.join(' | ');
  }

  return 'Não informado';
};
export const formatVehicleYear = (data: VehicleResponse) => {
  const { year, yearModel } = data;

  if (year && yearModel) {
    return `${year}/${yearModel}`;
  }
  if (year) {
    return year;
  }
  if (yearModel) {
    return yearModel;
  }
  return '';
};
