import { Workshop } from '../@types/interface';
import { QuotationNotificationResponse } from '../pages/common/QuotationNofication/interface';

export const getNotificationWorkshop = (
  newNotification: QuotationNotificationResponse | null,
): Workshop | null => {
  if (!newNotification) return null;
  if (!newNotification.event) return null;

  switch (newNotification.event) {
    case 'quotation_recieved':
      // return newNotification.quotation?.workshop ?? null;
      return null;
    case 'answer_quotation_confirmed':
      return newNotification.answer_quotation?.workshop ?? null;
    case 'quotation_answered':
      return null;
    // return newNotification.answer_quotation?.workshop ?? null;
    case 'answer_approved':
      return null;
    // return newNotification.quotation?.workshop ?? null;
    case 'answer_rejected':
      return null;
    // return newNotification.quotation?.workshop ?? null;
    case 'answer_quotation_reject':
      return null;
    // return newNotification.quotation?.workshop ?? null;
    default:
      return null;
  }
};
