import { useState } from 'react';
import { VehicleForm } from './components/forms/VehicleForm';
import { CustomerForm } from './components/forms/CustomerForm';
import { ServicesForm } from './components/forms/ServicesForm';
import styles from './order-service-create.module.css';

interface PageComponents {
  [key: number]: React.ReactNode;
}

export const CreateOrderService = () => {
  const [page, setPage] = useState(0);
  const [changeOwner, setChangeOwner] = useState(false);
  const [previousOwnerId, setPreviousOwnerId] = useState('');
  const [vehicleId, setVehicleId] = useState('');
  const [isSeparateComponent, setIsSeparateComponent] = useState(false);
  const [isSeparateCustomer, setIsSeparateCustomer] = useState(false);
  const [showTransferVehicle, setShowTransferVehicle] = useState(false);

  const pageComponents: PageComponents = {
    0: (
      <VehicleForm
        setPage={setPage}
        setPreviousOwnerId={setPreviousOwnerId}
        setVehicleId={setVehicleId}
        isSeparateComponent={isSeparateComponent}
        setIsSeparateComponent={setIsSeparateComponent}
        setIsSeparateCustomer={setIsSeparateCustomer}
        setShowTransferVehicle={setShowTransferVehicle}
      />
    ),
    1: (
      <CustomerForm
        previousOwnerId={previousOwnerId}
        setPage={setPage}
        changeOwner={changeOwner}
        setChangeOwner={setChangeOwner}
        isSeparateCustomer={isSeparateCustomer}
        setIsSeparateCustomer={setIsSeparateCustomer}
        setShowTransferVehicle={setShowTransferVehicle}
        showTransferVehicle={showTransferVehicle}
      />
    ),
    2: (
      <ServicesForm
        setPage={setPage}
        changeOwner={changeOwner}
        previousOwnerId={previousOwnerId}
        selectedVehicleId={vehicleId}
        isSeparateComponent={isSeparateComponent}
        isSeparateCustomer={isSeparateCustomer}
      />
    ),
  };

  return <div className={styles.container}>{pageComponents[page]}</div>;
};
