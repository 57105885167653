import api from '../../../services/api/api';
import {
  GetQuotationNotificationInput,
  QuotationNotificationResponse,
} from './interface';

export const getQuotationNotification = async (
  params: GetQuotationNotificationInput,
) => {
  const { data } = await api.get<QuotationNotificationResponse[]>(
    '/notification/quotation',
    {
      params,
    },
  );
  return data;
};
export const updateQuotationNotification = async (notificationId: string) => {
  const { data } = await api.put<QuotationNotificationResponse>(
    `/notification/read?id_notification=${notificationId}`,
  );
  return data;
};
