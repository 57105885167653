/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  ChangeEvent,
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { X } from '@phosphor-icons/react';
import styles from './input.module.css';
import imagePlaceholder from '../../assets/img/image-placeholder.svg';
import { Button } from '../Button';
import { ImagePicker } from './components/ImagePicker';
import { SearchList } from './components/SearchList';
import { InputText } from './components/InputText';
import { InputSearch } from './components/InputSearch';
import { InputPassword } from './components/InputPassword';

export interface Option {
  name: string;
  value: string;
  color?: string;
}

interface Props
  extends InputHTMLAttributes<HTMLInputElement | HTMLSelectElement> {
  name: string;
  value?: string;
  handleChange?: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  label?: string;
  error?: string;
  options?: Option[];
  type?:
    | 'text'
    | 'password'
    | 'select'
    | 'time'
    | 'date'
    | 'search'
    | 'image'
    | 'hidden'
    | 'images'
    | 'searchList'
    | 'imagePicker';
  handleIconSearch?: () => void;
  handleClearImage?: () => void;
  removeImage?: (name: string) => void;
  handleOptionClick?: (option: Option) => void;
  img_url?: string;
  img_urlArray?: { url: string; name: string }[];
  max_images?: number;
  isLoading?: boolean;
  filters?: boolean;
  controlClassName?: string;
}

export interface CommonInputProps
  extends InputHTMLAttributes<HTMLInputElement | HTMLSelectElement> {
  id: string;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  className?: string;
}

export const Input = ({
  filters = false,
  handleChange,
  name,
  value,
  label,
  error,
  type = 'text',
  options = [],
  handleIconSearch,
  handleClearImage,
  handleOptionClick,
  img_url = imagePlaceholder,
  removeImage,
  max_images = 3,
  img_urlArray = [],
  isLoading = false,
  controlClassName,
  ...rest
}: Props) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [selectedImageUrl, setSelectedImageUrl] = useState(img_url);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [selectedImages, setSelectedImages] = useState<File[]>([]);
  const [selectedImageUrls, setSelectedImageUrls] =
    useState<{ url: string; name: string }[]>(img_urlArray);

  const clearImageField = useCallback(() => {
    setSelectedImageUrl(imagePlaceholder);
    setSelectedImage(null);
    setSelectedImageUrls([]);
    setSelectedImages([]);
    if (handleClearImage) handleClearImage();
  }, [handleClearImage]);

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    // const stream = await turnOnCamera();
    if (handleChange) handleChange(event);

    const newSelectedFiles = event.target.files;
    if (newSelectedFiles) {
      const file = newSelectedFiles[0];

      setSelectedImageUrl(URL.createObjectURL(file) || imagePlaceholder);

      setSelectedImage(file);

      if (inputRef.current) inputRef.current.value = '';
    }
  };

  const handleFilesChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (handleChange) handleChange(event);

    const newSelectedFiles = event.target.files;

    if (newSelectedFiles) {
      let filesArray = [...Array.from(newSelectedFiles), ...selectedImages];
      let urls = filesArray.map(file => ({
        url: URL.createObjectURL(file) || imagePlaceholder,
        name: `${file.name}-${new Date().getTime()}`,
      }));

      if (filesArray.length > max_images) {
        filesArray = filesArray.slice(0, max_images);
        urls = urls.slice(0, max_images);
      }

      setSelectedImageUrls(urls);
      setSelectedImages(filesArray);

      if (inputRef.current) inputRef.current.value = '';
    }
  };

  const handleRemoveImage = (index: number) => {
    if (removeImage && index >= 0 && index < selectedImageUrls.length) {
      const imageToRemove = selectedImages[index];
      removeImage(imageToRemove.name);

      const updatedImages = selectedImages.filter((_, idx) => idx !== index);
      const updatedImageUrls = selectedImageUrls.filter(
        (_, idx) => idx !== index,
      );

      setSelectedImages(updatedImages);
      setSelectedImageUrls(updatedImageUrls);

      if (inputRef.current) inputRef.current.value = '';
    }
  };

  useEffect(() => {
    return () => {
      clearImageField();
    };
  }, [clearImageField]);

  const commonInputAttributes: CommonInputProps = {
    id: name,
    value,
    onChange: handleChange,
    className: error ? styles['input-error'] : styles.input,
    ...rest,
  };

  if (type === 'hidden') {
    return (
      <div className={styles['input-control']}>
        <input type="hidden" {...commonInputAttributes} />
      </div>
    );
  }
  if (type === 'text') {
    return (
      <InputText
        commonInputAttributes={commonInputAttributes}
        name={name}
        error={error}
        isLoading={isLoading}
        label={label}
      />
    );
  }
  if (type === 'password') {
    return (
      <InputPassword
        commonInputAttributes={commonInputAttributes}
        name={name}
        error={error}
        isLoading={isLoading}
        label={label}
      />
    );
  }
  if (type === 'search') {
    return (
      <InputSearch
        commonInputAttributes={commonInputAttributes}
        name={name}
        error={error}
        handleIconSearch={handleIconSearch}
        isLoading={isLoading}
        label={label}
        filters={filters}
        controlClassName={controlClassName}
      />
    );
  }
  if (type === 'searchList') {
    return (
      <SearchList
        commonInputAttributes={commonInputAttributes}
        name={name}
        label={label}
        options={options}
        handleChange={handleChange}
        handleIconSearch={handleIconSearch}
        handleOptionClick={handleOptionClick}
        error={error}
        isLoading={isLoading}
      />
    );
  }
  if (type === 'time') {
    return (
      <div className={styles['input-control']}>
        {label && <label htmlFor={name}>{label}</label>}
        <input type="time" {...commonInputAttributes} />
        {error && <span className={styles['error-text']}>{error}</span>}
      </div>
    );
  }
  if (type === 'date') {
    return (
      <div className={styles['input-control']}>
        {label && <label htmlFor={name}>{label}</label>}
        <input type="date" {...commonInputAttributes} />
        {error && <span className={styles['error-text']}>{error}</span>}
      </div>
    );
  }
  if (type === 'select') {
    return (
      <div className={styles['input-control']}>
        {label && <label htmlFor={name}>{label}</label>}
        <select {...commonInputAttributes}>
          {options &&
            options.map(option => (
              <option
                key={option.name}
                value={option.value}
                style={{ color: option.color }}
              >
                {option.name}
              </option>
            ))}
        </select>
        {error && <span className={styles['error-text']}>{error}</span>}
      </div>
    );
  }
  if (type === 'image') {
    return (
      <div className={styles['input-image-container']}>
        <div className={styles['input-image-control']}>
          <label htmlFor="image">Enviar Imagem:</label>
          <input
            type="file"
            name="image"
            id="image"
            ref={inputRef}
            onChange={handleFileChange}
          />
          <div className={styles['img-field']}>
            <Button
              variant="border"
              handleClick={() => inputRef?.current?.click()}
            >
              Selecionar
            </Button>

            <span>Nenhum arquivo selecionado</span>
          </div>
        </div>

        <div className={styles['preview-wrapper']}>
          <button
            type="button"
            onClick={() => {
              if (!selectedImage) {
                inputRef?.current?.click();
              }
            }}
          >
            <img
              src={selectedImageUrl}
              alt="visualizar imagem"
              className={styles.preview}
            />
          </button>
          <X
            style={
              selectedImage
                ? { display: 'flex', cursor: 'pointer' }
                : { display: 'none' }
            }
            onClick={clearImageField}
          />
        </div>
      </div>
    );
  }
  if (type === 'images') {
    return (
      <div className={styles['input-image-container']}>
        <div className={styles['input-image-control']}>
          <label htmlFor="image">Enviar Imagem:</label>
          <input
            type="file"
            name="image"
            id="image"
            ref={inputRef}
            onChange={handleFilesChange}
          />
          <div className={styles['img-field']}>
            <Button
              variant="border"
              handleClick={() => inputRef?.current?.click()}
            >
              Selecionar
            </Button>

            <span>Nenhum arquivo selecionado</span>
          </div>
        </div>

        <div className={styles['preview-wrapper']}>
          {[...Array(max_images)].map((_, index) => (
            <button
              type="button"
              onClick={() => {
                if (!selectedImage) {
                  inputRef?.current?.click();
                }
              }}
              key={index}
            >
              <img
                src={
                  index < selectedImageUrls.length
                    ? selectedImageUrls[index].url
                    : imagePlaceholder
                }
                alt="visualizar imagem"
                className={styles.preview}
              />
            </button>
          ))}

          <X
            style={
              selectedImage
                ? { display: 'flex', cursor: 'pointer' }
                : { display: 'none' }
            }
            onClick={clearImageField}
          />
        </div>
      </div>
    );
  }
  if (type === 'imagePicker') {
    return (
      <ImagePicker
        handleFilesChange={handleFilesChange}
        inputRef={inputRef}
        max_images={max_images}
        selectedImageUrls={selectedImageUrls}
        removeImage={index => {
          console.log(index);
          handleRemoveImage(index);
        }}
      />
    );
  }
  return null;
};
