import styles from './header-card.module.css';

interface Props {
  icon: React.ReactNode;
  label: string;
  value: number;
  onClick?: () => void;
  emphasis?: boolean;
  loading?: boolean;
  formatted?: boolean;
}

export const HeaderCard = ({
  icon,
  label,
  onClick,
  value,
  loading = false,
  emphasis = false,
  formatted = false,
}: Props) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };
  return (
    <div className={styles.container}>
      <button
        type="button"
        onClick={handleClick}
        style={{
          cursor: onClick ? 'pointer' : '',
        }}
      >
        <div className={styles.content}>
          <div className={`${styles.icon} ${emphasis ? styles.emphasis : ''}`}>
            {icon}
          </div>
          {loading ? (
            <div className={styles.loader}>
              <div />
              <div />
              <div />
            </div>
          ) : (
            <>
              <div className={styles.value}>
                {formatted ? value.toFixed(2) : value}
              </div>
              <div className={styles.label}>{label}</div>
            </>
          )}
        </div>
      </button>
    </div>
  );
};
