import { Quotation } from '../../../../../../../../@types/interface';
import { DesktopList } from './Desktop';

interface Props {
  quotationList: Quotation[];
  handleRowClick: (quotation: Quotation) => void;
  onScroll: () => void;
  isLoading: boolean;
  isEmpty?: boolean;
  notFound?: boolean;
  onOpenRedoModal: () => void;
}

export const QuotationList = (quotationListProps: Props) => {
  return <DesktopList {...quotationListProps} />;
};
