import { BudgetProps } from '../../../../../features/budget/interfaces';
import api from '../../../../../services/api/api';
// import { BudgetProps } from '../../interfaces';

export interface GetBudgetInput {
  query?: string;
}

export const getBudgetService = async (params: GetBudgetInput) => {
  const { data } = await api.get<BudgetProps[]>('/budget/v2', {
    params,
  });
  return data;
};
