/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { PlusSquare, MinusSquare } from '@phosphor-icons/react';
import { MagnifyingGlassPlus } from 'phosphor-react';
import styles from './quotation-description.module.css';
import { useResponsive } from '../../../../../../../../hooks/useResponsive';
import {
  formatDate,
  formatTime,
} from '../../../../../../../../utils/dateFunctions';
import { maskCpfCnpj } from '../../../../../../../../services/helpers/mask';
import Image from '../../../../../../../../shared/Image';
import useSelectedAnswer from '../../../../hooks/useSelectedAnswer';

export const QuotationDescription = () => {
  const { sizeMobile } = useResponsive();
  const [isClosed, setIsClosed] = useState(false);

  const handleDateTime = (value: Date) => {
    const newDate = new Date(value);
    const formattedDate = formatDate(newDate);
    const formattedTime = formatTime(newDate);
    return `${formattedDate} às ${formattedTime}`;
  };

  const { answer } = useSelectedAnswer();

  const quotationNumber = useMemo(() => {
    return answer?.quotation?.QUOTATION ?? '';
  }, [answer?.quotation?.QUOTATION]);

  const createdAt = useMemo(() => {
    if (answer?.quotation?.created_at) {
      return handleDateTime(answer.quotation.created_at);
    }
    return '---';
  }, [answer?.quotation?.created_at]);

  const workshopFantasyName = useMemo(() => {
    return answer?.quotation?.workshop.fantasy_name ?? '';
  }, [answer?.quotation?.workshop.fantasy_name]);

  const workshopDocument = useMemo(() => {
    return answer?.quotation?.workshop.cnpj
      ? maskCpfCnpj(answer.quotation.workshop.cnpj)
      : '---';
  }, [answer?.quotation?.workshop.cnpj]);

  const workshopAddress = useMemo(() => {
    return answer?.quotation?.workshop.address;
  }, [answer?.quotation?.workshop.address]);

  const agentName = useMemo(() => {
    return answer?.quotation?.agent?.user?.name || null;
  }, [answer?.quotation?.agent?.user?.name]);

  // const agent = useMemo(() => {
  //   return answer?.quotation?.agent ?? null;
  // }, [answer?.quotation?.agent]);

  // const agentUsername = useMemo(() => {
  //   return agent?.user?.name ?? '---';
  // }, [agent?.user?.name]);

  const paymentMethod = useMemo(() => {
    return answer?.quotation?.type_payment ?? 'Não informado';
  }, [answer?.quotation?.type_payment]);

  const vehicle = useMemo(() => {
    return answer?.quotation?.vehicle ?? null;
  }, [answer?.quotation?.vehicle]);

  const banners = useMemo(() => {
    return answer?.quotation?.banners ?? [];
  }, [answer?.quotation?.banners]);

  const comment = useMemo(() => {
    return answer?.quotation?.comment ?? '';
  }, [answer?.quotation?.comment]);

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      setIsClosed(previous => !previous);
    },
    [],
  );

  if (isClosed && sizeMobile)
    return (
      <div
        className={styles.closed}
        onClick={handleClick}
        style={{ position: 'relative' }}
      >
        <MagnifyingGlassPlus size={32} />
        Clique para expandir as informações
        <PlusSquare size={24} color="black" className={styles.icon} />
      </div>
    );

  return (
    <div className={styles.container}>
      <header className={styles['quotation-info']}>
        <h1 className={styles['quotation-info--label']}>
          Cotação {quotationNumber}
        </h1>
        <h3 className={styles['quotation-info--value']}>
          Criado em {createdAt}
        </h3>
      </header>
      {sizeMobile && (
        <div onClick={handleClick} className={styles.icon}>
          <MinusSquare size={24} color="black" />
        </div>
      )}
      <SectionInfo
        titleLabel="origem"
        titleValue={workshopFantasyName}
        withSeparator
      >
        <TextInfo label="cnpj/cpf" value={workshopDocument} />
        <TextInfo
          label="endereço"
          value={`${workshopAddress?.street ?? ''} ${
            workshopAddress?.number ?? ''
          }`}
        />
        <TextInfo label="bairro" value={`${workshopAddress?.district ?? ''}`} />
        <TextInfo label="cep" value={`${workshopAddress?.zip ?? ''}`} />
        <TextInfo label="forma de pagamento" value={paymentMethod} />
        {agentName && <TextInfo label="vendedor" value={agentName} />}
      </SectionInfo>
      <SectionInfo titleLabel="Modelo" titleValue={vehicle?.name ?? ''}>
        <TextInfo label="placa" value={vehicle?.license_plate ?? ''} />
        <TextInfo label="chassi" value={vehicle?.chassi ?? ''} />
        <TextInfo label="ano" value={vehicle?.year ?? ''} />

        <div className={styles.comment}>
          <span className={styles['comment--label']}>observações</span>
          <span className={styles['comment--value']}>{comment}</span>
        </div>
        <div className={styles['banner-list']}>
          {banners.map(banner => (
            <Image
              width={110}
              height={110}
              key={banner.id_banner}
              src={banner.img_url}
            />
          ))}
        </div>
      </SectionInfo>
    </div>
  );
};

interface TextInfoProps {
  label: string;
  value: string;
}

const TextInfo = ({ label, value }: TextInfoProps) => {
  return (
    <div className={styles['text-info']}>
      <span className={styles['text-info--label']}>{label}</span>
      <span className={styles['text-info--value']}>{value}</span>
    </div>
  );
};

interface SectionInfoProps {
  titleLabel: string;
  titleValue: string;
  children: ReactNode;
  withSeparator?: boolean;
}

const SectionInfo = ({
  titleLabel,
  titleValue,
  children,
  withSeparator,
}: SectionInfoProps) => {
  return (
    <div className={styles['section-info']}>
      <header>
        <h3 className={styles['section-info--label']}>{titleLabel}</h3>
        <h1 className={styles['section-info--value']}>{titleValue}</h1>
      </header>
      <section>{children}</section>
      {withSeparator && <hr />}
    </div>
  );
};
