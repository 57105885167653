import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { handleHtmlToPdf } from '../handleHtmlToPdf';
import { orderServicePdf } from './orderServicePdf';
import { Container } from '../components/jsx/Container';
import { Section } from '../components/jsx/Section';
import { InfoRow } from '../components/jsx/InfoRow';
import { ServiceTable } from '../components/jsx/ServiceTable';
import { maskCpfCnpj, maskPhone } from '../../../services/helpers/mask';
import { dateOffset, formatDate, formatTime } from '../../dateFunctions';
import { isWebView } from '../../detectUserAgent';
import { QRCode } from '../../qrcode';
import { Workshop } from '../../../@types/interface';
import { Repair } from '../../../pages/common/History/interfaces';
import { getOrderServiceDataController } from './controller';

interface Props {
  workshopData: Workshop | null;
}

export const OrderServiceReport = ({ workshopData }: Props) => {
  const [orderService, setOrderService] = useState<Repair | null>(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const orderServiceId = queryParams.get('orderServiceId');

  const fetchBudget = useCallback(async () => {
    const res = await getOrderServiceDataController(orderServiceId || '');

    if (res) {
      setOrderService(res);
    } else {
      setOrderService(null);
    }
  }, [orderServiceId]);

  useEffect(() => {
    fetchBudget();
  }, [fetchBudget]);

  const handleDownloadPdf = () => {
    if (orderService) {
      const html = orderServicePdf({
        orderService,
        workshopData,
      });
      const pdfProps = {
        html,
        name: 'pdf',
      };
      if (isWebView === '1') {
        window.ReactNativeWebView.postMessage(JSON.stringify(pdfProps));
      } else {
        handleHtmlToPdf(pdfProps.html, pdfProps.name);
      }
    }
  };

  const generateClientSection = () => {
    const items = [
      {
        name: 'Nome',
        value: orderService?.name ?? '-',
      },
      {
        name: 'Telefone',
        value: maskPhone(orderService?.phone ?? '-'),
      },
      {
        name: 'CPF/CNPJ',
        value: maskCpfCnpj(orderService?.user_workshop?.user?.document ?? '-'),
      },
    ];
    return (
      <Section title="Cliente">
        <InfoRow items={items} />
      </Section>
    );
  };

  const generateVehicleSection = () => {
    const km = orderService?.km ?? null;

    const kmFormatted = km !== null ? km.toLocaleString('pt-br') : '-';
    const items = [
      {
        name: 'Modelo',
        value: orderService?.vehicle?.name ?? '-',
      },
      {
        name: 'Placa',
        value: orderService?.vehicle?.license_plate ?? '-',
      },
      {
        name: 'km',
        value: kmFormatted,
      },
    ];
    return (
      <Section title="Veículo">
        <InfoRow items={items} />
      </Section>
    );
  };

  const generateServiceSection = () => {
    const discount = orderService?.discount ?? 0;
    const services =
      orderService?.child_services?.map(service => ({
        name: service.name ?? '-',
        quantity: service.quantity ?? 0,
        unit: service.quantity ? service.total / service.quantity : 0,
      })) ?? [];

    const initialDate =
      orderService?.date && orderService?.time
        ? dateOffset(new Date(`${orderService.date}T${orderService.time}Z`))
        : null;

    const finishDate =
      orderService?.finish_date && orderService?.finish_time
        ? dateOffset(
            new Date(
              `${orderService.finish_date}T${orderService.finish_time}Z`,
            ),
          )
        : null;

    return (
      <Section title="Serviços">
        <InfoRow
          items={[
            {
              name: 'Data de Início',
              value: initialDate
                ? `${formatDate(initialDate)} - ${formatTime(initialDate)}`
                : '-',
            },
            {
              name: 'Data de Término',
              value: finishDate
                ? `${formatDate(finishDate)} - ${formatTime(finishDate)}`
                : '-',
            },
          ]}
        />
        <InfoRow
          items={[
            {
              name: 'Defeitos',
              value: orderService?.defect ?? '-',
            },
            {
              name: 'Status',
              value: orderService?.finished ? 'Finalizado' : 'Em Andamento',
            },
          ]}
        />
        <ServiceTable discount={discount} services={services} />
      </Section>
    );
  };

  const generateCommentSection = () => {
    const items = [
      {
        name: 'Diagnóstico',
        value: orderService?.comment ?? '-',
        fullLine: true,
      },
    ];
    return (
      <Section title="Observações">
        <InfoRow items={items} />
      </Section>
    );
  };

  const generateQRCode = () => {
    if (
      orderService?.checklist &&
      orderService?.checklist?.banners?.length > 0 &&
      orderService?.checklist_id
    ) {
      return QRCode(
        `https://oficinas.autocenterapp.com/checklist-report?checklistId=${orderService.checklist_id}`,
      );
    }
    return null;
  };

  return (
    <Container
      handleDownloadPdf={handleDownloadPdf}
      workshopName={workshopData?.fantasy_name ?? 'Oficina Não encontrada'}
      documentName={`Ordem de Serviço - ${
        orderService?.external_id || orderService?.OS || '-'
      }`}
    >
      <InfoRow
        items={[
          {
            name: 'Data de Emissão',
            value: `${
              orderService?.created_at
                ? `${formatDate(
                    new Date(orderService.created_at),
                  )} - ${formatTime(new Date(orderService.created_at))}`
                : '-'
            }`,
          },
        ]}
      />
      {generateClientSection()}
      {generateVehicleSection()}
      {generateServiceSection()}
      {generateCommentSection()}
      {generateQRCode()}
    </Container>
  );
};
