/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FileText, MagnifyingGlass } from 'phosphor-react';
import { useDebounce } from 'use-debounce';
import { Checkbox } from '../../../../../../../../../../shared/Checkbox';
import styles from './companies.module.css';
import {
  findFavoritesWorkshopsController,
  findFavoritesWorkshopsV2Controller,
} from '../../../../../../controller';
import { WorkshopList, WorkshopOption } from '../../useSendQuotation';
import { Workshop } from '../../../../../../../../../../@types/interface';
import { Loading } from '../../../../../../../../../../shared/Loading';

interface WorkshopSearchCache {
  [key: string]: WorkshopOption[];
}

interface Props {
  workshopList: WorkshopList[];
  setWorkshopList: Dispatch<SetStateAction<WorkshopList[]>>;
  onSend: () => void;
  onReturn: () => void;
}

export type SearchTypeOptions = 'workshop' | 'service' | 'product';

export const Companies = ({
  setWorkshopList,
  workshopList,
  onSend,
  onReturn,
}: Props) => {
  const [searchCache, setSearchCache] = useState<WorkshopSearchCache>({});
  const [isDefaultDataLoading, setIsDefaultDataLoading] = useState(false);
  const [isSearchingForWorkshop, setIsSearchingForWorkshop] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedQuery] = useDebounce(searchQuery, 500);

  const [searchedOptionList, setSearchedOptionList] = useState<WorkshopList[]>(
    [],
  );

  const findWorkshops = useCallback(
    async (query: string) => {
      if (query.trim().length === 0) {
        setIsSearchingForWorkshop(false);
        setNoResults(false); // Reseta o estado de resultados
        return;
      }

      setIsSearchingForWorkshop(true);
      setNoResults(false); // Reseta no início da busca

      try {
        if (searchCache[`${query}`]) {
          const cachedOptions = searchCache[`${query}`].map(option => ({
            option,
            selected: workshopList.some(
              existingWorkshop =>
                existingWorkshop.option.value === option.value &&
                existingWorkshop.selected,
            ),
          }));
          setSearchedOptionList(cachedOptions);
        } else {
          const res = await findFavoritesWorkshopsV2Controller(query);
          if (res && res.length > 0) {
            const normalizedOptions = res.map(workshop => ({
              name: workshop.fantasy_name,
              value: workshop.id_workshop,
            }));

            setSearchCache(prevCache => ({
              ...prevCache,
              [`${query}`]: normalizedOptions,
            }));

            const newOptions = normalizedOptions.map(option => ({
              option,
              selected: workshopList.some(
                existingWorkshop =>
                  existingWorkshop.option.value === option.value &&
                  existingWorkshop.selected,
              ),
            }));
            setSearchedOptionList(newOptions);
          } else {
            setSearchedOptionList([]); // Nenhum resultado encontrado
            setNoResults(true); // Atualiza para exibir a mensagem
          }
        }
      } catch (error) {
        console.error('Erro ao buscar workshops:', error);
      } finally {
        setIsSearchingForWorkshop(false);
      }
    },
    [searchCache, workshopList],
  );

  // merge the previously was checked and the returns from favorite workshops
  const mergeFavoriteWorkshops = useCallback(
    (newWorkshopList: Workshop[], existingWorkshops: WorkshopList[]) => {
      const newWorkshops = newWorkshopList.filter(
        newWorkshop =>
          !existingWorkshops.some(
            existingWorkshop =>
              existingWorkshop.option.value === newWorkshop.id_workshop,
          ),
      );

      setWorkshopList([
        ...existingWorkshops,
        ...newWorkshops.map(newWorkshop => ({
          option: {
            name: newWorkshop.fantasy_name,
            value: newWorkshop.id_workshop,
          },
          selected: false,
        })),
      ]);
    },
    [setWorkshopList],
  );

  const findFavoriteWorkshops = useCallback(async () => {
    setIsDefaultDataLoading(true);
    try {
      const res = await findFavoritesWorkshopsController();
      return res;
    } finally {
      setIsDefaultDataLoading(false);
    }
  }, []);

  const handleFavoriteWorkshops = useCallback(async () => {
    const newWorkshopList = await findFavoriteWorkshops();
    if (newWorkshopList) {
      mergeFavoriteWorkshops(newWorkshopList, workshopList);
    }
  }, [findFavoriteWorkshops, mergeFavoriteWorkshops]);

  useEffect(() => {
    handleFavoriteWorkshops();
  }, [handleFavoriteWorkshops]);

  const handleCheckboxChange = (
    value: string,
    setList: Dispatch<SetStateAction<WorkshopList[]>>,
  ) => {
    setList(previous => {
      return sortWorkshopList(
        previous.map(option =>
          option.option.value === value
            ? { ...option, selected: !option.selected }
            : option,
        ),
      );
    });
    scrollListToTop();
  };

  const sortWorkshopList = (workshops: WorkshopList[]) => {
    return workshops.sort((a, b) => {
      if (a.selected && !b.selected) return -1;
      if (!a.selected && b.selected) return 1;
      return 0;
    });
  };

  const handleSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.checked;
    setSelectAll(newValue);
    setSearchedOptionList(previous =>
      previous.map(option => ({ ...option, selected: newValue })),
    );
    scrollListToTop();
  };

  const companiesListRef = useRef<HTMLUListElement>(null);

  const scrollListToTop = () => {
    if (companiesListRef && companiesListRef.current) {
      companiesListRef.current.scrollTo({
        top: 0,
      });
    }
  };

  const handleResetSearch = useCallback(() => {
    setSearchQuery('');
    setSearchedOptionList([]);
    setSelectAll(false);
    setNoResults(false);
  }, []);

  const handleAddOptions = useCallback(
    (workshops: WorkshopList[], existingWorkshops: WorkshopList[]) => {
      // Filtra apenas os workshops selecionados
      const selectedWorkshops = workshops.filter(workshop => workshop.selected);

      // Atualiza o status de "selected" dos workshops que já existem na lista
      const updatedExistingWorkshops = existingWorkshops.map(
        existingWorkshop => {
          const isSelected = selectedWorkshops.some(
            selectedWorkshop =>
              selectedWorkshop.option.value === existingWorkshop.option.value,
          );
          return isSelected
            ? { ...existingWorkshop, selected: true }
            : existingWorkshop;
        },
      );

      // Adiciona apenas os novos workshops que não estão na lista existente
      const newWorkshops = selectedWorkshops.filter(
        selectedWorkshop =>
          !existingWorkshops.some(
            existingWorkshop =>
              existingWorkshop.option.value === selectedWorkshop.option.value,
          ),
      );

      // Atualiza a lista com os existentes atualizados e os novos adicionados
      setWorkshopList(
        sortWorkshopList([...updatedExistingWorkshops, ...newWorkshops]),
      );

      // Limpa a pesquisa e a lista de resultados
      setSearchQuery('');
      setSearchedOptionList([]);
    },
    [setWorkshopList],
  );

  useEffect(() => {
    if (debouncedQuery.trim().length > 0) {
      setIsSearchingForWorkshop(true);
      findWorkshops(debouncedQuery);
      setSelectAll(false);
    } else {
      setIsSearchingForWorkshop(false);
      setSearchedOptionList([]);
      setSelectAll(false);
    }
  }, [debouncedQuery, findWorkshops]);

  return (
    <div className={styles['companies-container']}>
      <div className={styles['search-input-container']}>
        <input
          type="search"
          className={styles['search-input']}
          placeholder="Pesquise por nome, peça ou serviço"
          value={searchQuery}
          onChange={e => {
            setSearchQuery(e.target.value);
            setNoResults(false);
          }}
        />
        <div className={styles['search-input--icon']}>
          <MagnifyingGlass size={32} />
        </div>
      </div>

      {searchQuery.trim().length > 0 && (
        <Checkbox
          isChecked={selectAll}
          name="selectAll"
          theme="green"
          customSize="small"
          handleChange={handleSelectAll}
          label="Selecionar todos"
          style={{
            fontWeight: '600',
            color: '#333',
            marginLeft: 'auto',
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '1rem',
          }}
        />
      )}

      <div className={styles['companies-list']}>
        {isSearchingForWorkshop || isDefaultDataLoading ? (
          <div className={styles['loader-list']}>
            <Loading />
            <Loading />
            <Loading />
          </div>
        ) : noResults ? (
          <div className={styles['no-results']}>
            <FileText size={104} weight="regular" color="#263238" />
            Nenhum resultado encontrado para "{searchQuery}".
          </div>
        ) : (
          <>
            {(searchQuery.trim().length > 0
              ? searchedOptionList
              : workshopList
            ).map(item => (
              <div
                key={item.option.value}
                className={`${styles['company-option']} ${
                  item.selected ? styles.selected : ''
                }`}
              >
                <Checkbox
                  isChecked={item.selected}
                  name={item.option.value}
                  theme="green"
                  customSize="small"
                  handleChange={() =>
                    handleCheckboxChange(
                      item.option.value,
                      searchQuery.trim().length > 0
                        ? setSearchedOptionList
                        : setWorkshopList,
                    )
                  }
                  label={item.option.name}
                  style={{
                    fontWeight: '600',
                    color: '#333',
                  }}
                />
              </div>
            ))}
          </>
        )}
      </div>

      {searchQuery.trim().length > 0 ? (
        <div className={styles.actions}>
          <button
            type="button"
            className={styles['back-btn']}
            onClick={handleResetSearch}
          >
            Voltar
          </button>
          {searchedOptionList.length > 0 && (
            <button
              type="button"
              className={styles['send-btn']}
              onClick={() => handleAddOptions(searchedOptionList, workshopList)}
            >
              Adicionar
            </button>
          )}
        </div>
      ) : (
        <div className={styles.actions}>
          <button
            type="button"
            className={styles['back-btn']}
            onClick={onReturn}
            id="back-to-draft-btn"
          >
            Página Anterior
          </button>
          <button
            type="button"
            className={styles['send-btn']}
            onClick={onSend}
            id="send-quotation-btn"
          >
            Enviar
          </button>
        </div>
      )}
    </div>
  );
};
