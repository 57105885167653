/**
 * BridgeWebView é um objeto que facilita a comunicação entre a aplicação web
 * dentro de uma WebView e o aplicativo React Native via `postMessage`.
 */
const bridgeWebView = {
  /**
   * Envia uma mensagem de log para o aplicativo React Native.
   *
   * @param {string} value - A mensagem de log a ser enviada.
   */
  log(value: string) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ type: 'log', value }),
    );
  },

  /**
   * Envia uma mensagem de alerta para o aplicativo React Native.
   *
   * @param {string} value - A mensagem de alerta que será exibida no aplicativo.
   */
  alert(value: string) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ type: 'alert', value }),
    );
  },

  /**
   * Executa uma função dentro do aplicativo React Native.
   *
   * @template T
   * @param {() => T} fn - A função a ser executada no React Native.
   */
  callback<T>(fn: () => T) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ type: 'callback', value: fn.toString() }),
    );
  },

  /**
   * Realiza login e logout no OneSignal.
   */

  loginOneSignal(id: string) {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ type: 'loginOneSignal', value: id }),
    );
  },

  logoutOnesignal() {
    window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'logout' }));
  },

  /**
   * Envia dados genéricos para o aplicativo React Native, como informações
   * de PDF ou ID de usuário.
   *
   * @param {any} data - Os dados a serem enviados. Pode ser um objeto, string, ou qualquer outro tipo.
   */
  send(data: any) {
    window.ReactNativeWebView.postMessage(JSON.stringify(data));
  },
};

export default bridgeWebView;
