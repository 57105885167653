/* eslint-disable react/jsx-no-useless-fragment */
import { ReactNode, useEffect, useRef } from 'react';
import { X } from 'phosphor-react';
import styles from './modal.module.css';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  title: string;
}

export const Modal = ({ isOpen, onClose, children, title }: Props) => {
  const modalRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (isOpen && modalRef.current) {
      modalRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [isOpen]);

  if (isOpen) {
    return (
      <div className={styles.overlay}>
        <div className={styles.content} ref={modalRef}>
          <div className={styles.header}>
            <h2>{title}</h2>
            <button type="button" onClick={onClose}>
              <X size={32} color="#fff" />
            </button>
          </div>
          <div className={styles.body}>{children}</div>
        </div>
      </div>
    );
  }
  return <></>;
};
