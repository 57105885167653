/* eslint-disable react/jsx-no-useless-fragment */
import { X } from 'phosphor-react';
import { useEffect, useRef } from 'react';
import styles from './visitor-modal.module.css';
import visitor from '../../assets/img/visitor-image.svg';
import { sendWhatsappMessage } from '../../utils/sendWhatsappMessage';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
}

export const VisitorModal = ({
  isOpen,
  onClose,
  title = 'Faça seu cadastro',
}: Props) => {
  const handleClick = () => {
    sendWhatsappMessage(
      'Olá! Quero fazer parte do Autocenter',
      '+558791080605',
    );
  };
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen && modalRef.current) {
      modalRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [isOpen]);

  if (isOpen) {
    return (
      <div className={styles.overlay}>
        <div className={styles.content} ref={modalRef}>
          <div className={styles.header}>
            <h2>{title}</h2>
            <button type="button" onClick={onClose}>
              <X size={32} color="#6BB56A" />
            </button>
          </div>
          <div className={styles.body}>
            <p>Crie sua conta e aproveite todos os benefícios! </p>
            <img src={visitor} alt="Visitor" />
            <button type="button" onClick={handleClick}>
              Entrar em Contato
            </button>
          </div>
        </div>
      </div>
    );
  }
  return <></>;
};
