import message from '../../../utils/message';
import {
  deleteNotification,
  getNotifications,
  setNotificationAsRead,
} from './model';

export const getNotificationsController = async () => {
  try {
    const response = await getNotifications();

    return response;
  } catch (error) {
    message(error, 'error');
    return Promise.reject(error);
  }
};

export const deleteNotificationController = async (id_notification: string) => {
  try {
    const response = await deleteNotification(id_notification);
    return response;
  } catch (error) {
    message(error, 'error');
    return Promise.reject(error);
  }
};

export const handleReadNotification = async (id_notification: string) => {
  if (id_notification && id_notification.length !== 0) {
    const response = await setNotificationAsRead(id_notification);
    return response;
  }
  return false;
};
