import { useEffect, useState } from 'react';
import { Quotation } from '../../../../../../../@types/interface';
import { QuotationFilterOptions } from '../../../../workshop/interface';
import useSelectedQuotation from '../../../../workshop/hooks/useSelectedQuotation';

interface Props {
  fetchQuotationsByFilter: (
    filterValue: string,
    filterType: QuotationFilterOptions,
  ) => void;
}

export const useCreatedQuotation = ({ fetchQuotationsByFilter }: Props) => {
  const [filterType, setFilterType] = useState<QuotationFilterOptions>('code');
  const [filterValue, setFilterValue] = useState('');

  const selectedQuotation = useSelectedQuotation();

  const handleDateSearch = (period: string[]) => {
    // clear search state for invalid period
    if (period.length !== 2) {
      setFilterValue('');
    } else {
      const [initialDate, finalDate] = period;
      if (initialDate.trim().length > 0 && finalDate.trim().length > 0) {
        setFilterValue(`["${initialDate}", "${finalDate}"]`);
      }
    }
  };
  const handleSearch = (query: string) => {
    if (filterType === 'plate') setFilterValue(query.toUpperCase());
    else setFilterValue(query);
  };

  useEffect(() => {
    if (filterValue.trim().length > 0) {
      fetchQuotationsByFilter(filterValue, filterType);
    }
  }, [fetchQuotationsByFilter, filterType, filterValue]);

  const handleRowClick = (quotation: Quotation) => {
    if (
      !selectedQuotation.quotation ||
      selectedQuotation.quotation.id_quotation !== quotation.id_quotation
    ) {
      selectedQuotation.onSelect(quotation);
    }
    if (
      selectedQuotation.quotation &&
      selectedQuotation.quotation.id_quotation === quotation.id_quotation
    ) {
      selectedQuotation.onClear();
    }
  };

  return {
    handleRowClick,
    selectedQuotation,
    filterType,
    setFilterType,
    handleDateSearch,
    handleSearch,
    filterValue,
  };
};
