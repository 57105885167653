export const validateCPF = (document: string) => {
  const formattedDocument = document.replace(/[^\d]+/g, '');
  let accumulator = 0;
  let rest = 0;

  if (formattedDocument.length !== 11) return false;
  if (formattedDocument === '00000000000') return false;

  for (let i = 1; i <= 9; i++)
    accumulator += parseInt(formattedDocument.charAt(i - 1), 10) * (11 - i);
  rest = (accumulator * 10) % 11;
  if (rest === 11 || rest === 10) rest = 0;
  if (rest !== parseInt(formattedDocument.charAt(9), 10)) return false;

  accumulator = 0;
  rest = 0;

  for (let i = 1; i <= 10; i++)
    accumulator += parseInt(formattedDocument.charAt(i - 1), 10) * (12 - i);
  rest = (accumulator * 10) % 11;
  if (rest === 11 || rest === 10) rest = 0;
  if (rest !== parseInt(formattedDocument.charAt(10), 10)) return false;

  return true;
};

export const validateCNPJ = (document: string) => {
  const formattedDocument = document.replace(/[^\d]+/g, '');

  if (formattedDocument.length !== 14) return false;

  if (/^(\d)\1{13}$/.test(formattedDocument)) return false;

  let accumulator = 0;
  let rest;
  let weight = 2;

  for (let i = 11; i >= 0; i--) {
    accumulator += parseInt(formattedDocument.charAt(i), 10) * weight;
    weight = weight + 1 > 9 ? 2 : weight + 1;
  }

  rest = accumulator % 11;
  const firstVerifierDigit = rest < 2 ? 0 : 11 - rest;

  if (parseInt(formattedDocument.charAt(12), 10) !== firstVerifierDigit)
    return false;

  accumulator = 0;
  weight = 2;

  for (let i = 12; i >= 0; i--) {
    accumulator += parseInt(formattedDocument.charAt(i), 10) * weight;
    weight = weight + 1 > 9 ? 2 : weight + 1;
  }

  rest = accumulator % 11;
  const secondVerifierDigit = rest < 2 ? 0 : 11 - rest;

  return parseInt(formattedDocument.charAt(13), 10) === secondVerifierDigit;
};
