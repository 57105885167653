import { Faders, MagnifyingGlass } from 'phosphor-react';
import { Dispatch, SetStateAction } from 'react';
import styles from './search-bar.module.css';

interface Props {
  licensePlate: string;
  setLicensePlate: Dispatch<SetStateAction<string>>;
}

export const SearchBar = ({ licensePlate, setLicensePlate }: Props) => {
  return (
    <div className={styles['search-bar-container']}>
      <div className={styles['input-control']}>
        <input
          type="text"
          placeholder="Pesquisar por OS, Cliente, ou Placa"
          value={licensePlate}
          onChange={e => {
            setLicensePlate(e.target.value.toUpperCase());
          }}
        />
        <MagnifyingGlass size="1.6rem" color="#A5A5A5" weight="bold" />
      </div>
      {/* <div className={styles['filter-select']}>
        <Faders size="2rem" color="#A5A5A5" weight="bold" />
      </div> */}
    </div>
  );
};
