import styled from 'styled-components';
import pixel from '../../styles/size';

export const Container = styled.div`
  overflow: auto;
  width: ${pixel(750, 1920)};
  padding: 0 10px;
  margin: 0 auto;
  margin-top: 70px;

  .addBtn {
    background-color: transparent;
    width: 100%;
    text-align: left;
    border: none;
    font-size: 1rem;
    padding: 1rem 0;
  }
  .addBtn:hover {
    background-color: #d6d7d833;
  }
  @media screen and (max-width: 768px) {
    & {
      width: 95vw;
    }
  }
  @media screen and (max-width: 480px) {
    & {
      max-width: 30rem;
    }
  }
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;

  thead th {
    padding: 0 0.5rem;
  }
  tr th,
  input {
    font-size: 1rem;
    font-family: 'Montserrat';
    color: #1e1e1e;
    text-align: center;
  }

  tr th {
    border: 1px solid #eeeeee;
  }

  tr td {
    border: 1px solid #eeeeee;
  }
  thead th {
    font-weight: 'bold';
    border: 0;
    font-size: 0.9rem;
  }
  tbody .id {
    border-left: none;
  }
  tbody .total {
    border-right: none;
  }
  input {
    height: 100%;
    width: 100%;
    background-color: transparent;
    border: none;
    padding: 12px 12px;
  }

  input:hover {
    background-color: #d6d7d833;
  }

  input:focus {
    outline: 1px solid #ccc;
    border: 1px solid #ccc;
  }
  .removeBtn {
    border: none;

    button {
      background: transparent;
      margin-left: 0.5rem;
      border-radius: 50%;
      border: none;
    }
  }
`;
