import { getOrderServiceData } from './model';
import { handleError } from '../../handleError';

export const getOrderServiceDataController = async (orderServiceId: string) => {
  try {
    const res = await getOrderServiceData(orderServiceId);
    return res;
  } catch (error) {
    handleError(error);
  }
};
