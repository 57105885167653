/* eslint-disable @typescript-eslint/no-empty-function */
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { QuotationNotificationResponse } from '../pages/common/QuotationNofication/interface';
import { useSocket } from '../hooks/useSocket';
import { getQuotationNotificationController } from '../pages/common/QuotationNofication/controller';
import { NotificationProps } from '../pages/common/Notifications/model';
import { NotificationComponent } from '../shared/NotificationComponent';
import { getNotificationsController } from '../pages/common/Notifications/controller';
import { isAuthenticated } from '../services/api/auth';
import { getNotificationLink } from '../utils/getNotificationLink';
import { useAuth } from '../hooks/useAuth';

interface NotificationState {
  quotationNotifications: QuotationNotificationResponse[];
  generalNotifications: NotificationProps[];
  lastNotificationPlayedTime: number;
}

interface NotificationContextType {
  quotationNotifications: QuotationNotificationResponse[];
  generalNotifications: NotificationProps[];
  setNotificationState: Dispatch<SetStateAction<NotificationState>>;
  lastNotificationPlayedTime: number;
}

const NotificationContext = createContext<NotificationContextType | null>(null);

export const NotificationProvider = ({ children }: { children: ReactNode }) => {
  const { socketInstance } = useSocket();
  const navigate = useNavigate();

  const [notificationState, setNotificationState] = useState<NotificationState>(
    {
      quotationNotifications: [],
      generalNotifications: [],
      lastNotificationPlayedTime: 0,
    },
  );

  const [newNotification, setNewNotification] =
    useState<QuotationNotificationResponse | null>(null);

  const [shouldNotify, setShouldNotify] = useState(false);

  const { isAgent } = useAuth();

  const fetchQuotationNotifications = useCallback(async () => {
    const res = await getQuotationNotificationController({
      page: 1,
      limit: 999,
    });
    if (res) {
      setNotificationState(previous => ({
        ...previous,
        quotationNotifications: res,
      }));
    }
  }, []);

  const fetchGeneralNotifications = useCallback(async () => {
    const res = await getNotificationsController();
    if (res) {
      setNotificationState(previous => ({
        ...previous,
        generalNotifications: res,
      }));
    }
  }, []);

  useEffect(() => {
    socketInstance.on('notification_general', (data: NotificationProps) => {
      console.log('notificação geral', data);
      setNotificationState(previous => ({
        ...previous,
        generalNotifications: [data, ...previous.generalNotifications],
      }));
    });

    socketInstance.on(
      'notification_quotation',
      (data: QuotationNotificationResponse) => {
        console.log('notificação cotação', data);
        setNewNotification(data);
        setNotificationState(previous => ({
          ...previous,
          quotationNotifications: [data, ...previous.quotationNotifications],
        }));
        setShouldNotify(true);
      },
    );
    return () => {
      socketInstance.off('notification_general');
      socketInstance.off('notification_quotation');
    };
  }, [socketInstance]);

  useEffect(() => {
    if (isAuthenticated()) {
      fetchQuotationNotifications();
      fetchGeneralNotifications();
    }
  }, [fetchGeneralNotifications, fetchQuotationNotifications]);

  const contextValue = useMemo(
    () => ({
      ...notificationState,
      setNotificationState,
    }),
    [notificationState],
  );

  const handleRedirect = useCallback(() => {
    const basePath = isAgent ? '' : '';
    const redirectLink = getNotificationLink(newNotification);

    setNewNotification(null);
    setShouldNotify(false);
    navigate(`${basePath}${redirectLink}`);
  }, [isAgent, navigate, newNotification]);

  return (
    <NotificationContext.Provider value={contextValue}>
      {children}
      {newNotification && shouldNotify && (
        <NotificationComponent
          title={newNotification.title}
          content={newNotification.message}
          onClick={handleRedirect}
          onClose={() => {
            setShouldNotify(false);
            setNewNotification(null);
          }}
        />
      )}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  return useContext(NotificationContext) as NotificationContextType;
};
