import { AnswerQuotation } from '../../../../@types/interface';
import api from '../../../../services/api/api';
import {
  ChangeIsWatchingStatusInput,
  IsQuotationBeingWatchResponse,
} from './interface';

export const changeIsWatchingStatus = async ({
  id,
  watchState,
}: ChangeIsWatchingStatusInput) => {
  const { data } = await api.put<AnswerQuotation>(
    `/quotation/answer/watching/${id}?watch_state=${watchState}`,
  );

  return data;
};

export const isQuotationBeingWatch = async (id: string) => {
  const { data } = await api.get<IsQuotationBeingWatchResponse>(
    `/quotation/answer/is_being_watch/${id}`,
  );

  return data;
};
