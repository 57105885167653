import * as yup from 'yup';

export const validateSchema = (
  schema: yup.ObjectSchema<any> | yup.ArraySchema<any>,
  data: any,
) => {
  try {
    schema.validateSync(data, { abortEarly: false });
    return true;
  } catch (validationError) {
    if (validationError instanceof yup.ValidationError) {
      return validationError.errors[0];
    }
    return false;
  }
};
