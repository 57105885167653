import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ChecklistResponse, getChecklistById } from './model';
import styles from './checklist-report.module.css';

export const ChecklistReport = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const checklistId = queryParams.get('checklistId');
  const [checklist, setChecklist] = useState<ChecklistResponse | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [zoomInterval, setZoomInterval] = useState<NodeJS.Timeout | null>(null); // Armazena o intervalo de zoom

  const handleImageClick = (imgUrl: string) => {
    setSelectedImage(imgUrl);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setZoomLevel(1); // Reseta o zoom ao fechar o modal
  };

  const handleZoom = (direction: 'in' | 'out') => {
    setZoomLevel(
      prev =>
        direction === 'in'
          ? Math.min(prev + 0.2, 3) // Limita o zoom máximo a 3x
          : Math.max(prev - 0.2, 1), // Limita o zoom mínimo a 1x
    );
  };

  const handleZoomHoldStart = (direction: 'in' | 'out') => {
    const interval = setInterval(() => handleZoom(direction), 100); // Dispara a cada 100ms
    setZoomInterval(interval);
  };

  const handleZoomHoldEnd = () => {
    if (zoomInterval) {
      clearInterval(zoomInterval); // Para o zoom ao soltar
      setZoomInterval(null);
    }
  };

  const handleResetZoom = () => setZoomLevel(1);

  const fetchChecklist = async () => {
    if (!checklistId) return;
    setIsLoading(true);
    setError(null);

    try {
      const res = await getChecklistById(checklistId);
      if (res) {
        setChecklist(res);
      } else {
        setError('Nenhum checklist encontrado.');
      }
    } catch (err) {
      setError('Erro ao carregar o checklist.');
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchChecklist();
  }, [checklistId]);

  return (
    <>
      <h1>Imagens</h1>
      {isLoading && <p>Carregando...</p>}
      {error && <p className={styles.error}>{error}</p>}
      <div className={styles.container}>
        {checklist &&
          checklist.banners.map((banner, index) => (
            <button
              key={banner.id_banner || index}
              type="button"
              className={styles.thumbnailButton}
              onClick={() => handleImageClick(banner.img_url)}
            >
              <img
                src={banner.img_url}
                alt={banner.title || 'Imagem sem descrição'}
                className={styles.thumbnail}
              />
            </button>
          ))}
      </div>

      {/* Modal */}
      {selectedImage && (
        <div
          className={styles.modal}
          onClick={closeModal}
          role="button"
          tabIndex={0}
          onKeyDown={e => {
            if (e.key === 'Enter' || e.key === ' ') {
              closeModal();
            }
          }}
        >
          <div
            className={styles.imageContainer}
            role="presentation"
            onClick={e => e.stopPropagation()}
          >
            <img
              src={selectedImage}
              alt="Imagem expandida"
              className={styles.expandedImage}
              style={{
                transform: `scale(${zoomLevel})`,
                transition: 'transform 0.3s ease-in-out',
              }}
            />
            <div className={styles.controls}>
              <button
                type="button"
                onMouseDown={() => handleZoomHoldStart('in')}
                onMouseUp={handleZoomHoldEnd}
                onMouseLeave={handleZoomHoldEnd}
              >
                +
              </button>
              <button
                type="button"
                onMouseDown={() => handleZoomHoldStart('out')}
                onMouseUp={handleZoomHoldEnd}
                onMouseLeave={handleZoomHoldEnd}
              >
                -
              </button>
              <button type="button" onClick={handleResetZoom}>
                Resetar
              </button>
              <button
                type="button"
                className={styles.closeButton}
                onClick={closeModal}
              >
                Fechar
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
