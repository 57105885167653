import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../../hooks/useAppSelector';
import { setBudgets } from '../../../../../features/budget/budget-slice';
import { BudgetProps } from '../../../../../features/budget/interfaces';
import { getBudgetController } from '../../../../workshop/Budget/components/BudgetList/controller';

interface Props {
  query: string;
}

export const useBudgetData = ({ query }: Props) => {
  const [dataFiltered, setDataFiltered] = useState<BudgetProps[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);

  const budgetsFromState = useAppSelector(state => state.budget.budgets);
  const dispatch = useDispatch();

  const fetchFilteredData = useCallback(async () => {
    setIsLoading(true);
    const budgetsResponse = await getBudgetController({
      query,
    });

    if (budgetsResponse) {
      setDataFiltered(budgetsResponse);
    }
    setIsLoading(false);
  }, [query]);

  const fetchData = useCallback(
    async (existingBudgetFromState: BudgetProps[]) => {
      if (!hasMoreData || isLoading) {
        return;
      }

      setIsLoading(true);
      const budgetsResponse = await getBudgetController({});

      if (budgetsResponse) {
        const newBudgets = budgetsResponse.filter(budget => {
          return !existingBudgetFromState.some(
            existingBudget => budget.id_budget === existingBudget.id_budget,
          );
        });

        if (newBudgets.length > 0) {
          dispatch(
            setBudgets(
              [...existingBudgetFromState, ...newBudgets].sort(
                (a, b) =>
                  new Date(b.created_at).getTime() -
                  new Date(a.created_at).getTime(),
              ),
            ),
          );
        } else {
          setHasMoreData(false);
        }
      }
      setIsLoading(false);
    },
    [dispatch, hasMoreData, isLoading],
  );

  useEffect(() => {
    if (query.length > 0) {
      setHasMoreData(true);
    } else {
      setHasMoreData(false);
    }
  }, [query]);

  useEffect(() => {
    if (query.length > 0) {
      fetchFilteredData();
    } else if (hasMoreData) {
      fetchData(budgetsFromState);
      setHasMoreData(false);
    }
  }, [query, hasMoreData, budgetsFromState]);

  return {
    budgetsFromState,
    isLoading,
    hasMoreData,
    dataFiltered,
  };
};
