import styled from 'styled-components';
import pixel from '../../../styles/size';
import { COLORS } from '../../../styles/colors';

export const MainColumn = styled.div`
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 27vh);
  width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
  @media screen and (max-width: 480px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;
  margin-bottom: 1rem;
`;

export const InfoRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;
  margin-bottom: 0.5rem;
  padding-right: calc(${pixel(6)} + 0.5rem);
  text-align: center;

  font-size: 1rem;
  font-weight: bold;

  @media screen and (max-width: 480px) {
    display: none;
  }
`;

export const RowCenter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  /* height: 100vh; */
  /* gap: 1rem; */
  padding-bottom: 0.5rem;
  //padding-right: 0.5rem;
  // padding-top: 1rem;
  //margin-right: -0.5rem;
  /* margin-bottom: 1rem; */

  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: ${pixel(6)};
  }

  ::-webkit-scrollbar-track {
    background: #e9e9e9;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${COLORS.GREEN};
    border-radius: 10px;
  }
`;

export const DivisionLine = styled.div`
  width: 100%;
  height: 6px;
  border-radius: 5px;
  margin-bottom: 0.5rem;
  background: #d9d9d9;
`;

export const NotificationCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 88.813rem;
  margin: 0.5rem auto;

  position: relative;

  border-bottom: solid black 2px;
  align-items: flex-start;
  padding-top: 1rem;

  @media screen and (max-width: 480px) {
    flex-wrap: wrap;
  }

  :hover {
    box-shadow: 2px 2px rgba(1, 1, 1, 0.2);
  }
`;

export const NotificationCardIsRead = styled.div`
  position: relative;
  & > div {
    position: absolute;
    top: -13px;
    width: 25%;
    min-width: 52px;
    padding: 0.1rem 0.25rem;

    text-align: center;
    font-size: 14px;
    font-weight: 900;
    font-family: Inter;
    color: ${COLORS.WHITE};

    background: ${COLORS.NEWNOTIFICATION};
    border-radius: 23px;
  }

  justify-content: flex-start;
  width: 50%;

  @media screen and (max-width: 480px) {
    left: 35%;
    right: 35%;
    width: 30%;
  }
`;

export const NotificationCardInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  width: 90%;

  cursor: pointer;
`;

export const NotificationIcon = styled.div`
  display: flex;
  justify-content: center;
  width: 10%;

  & > img {
    width: 2.5rem;
  }

  @media screen and (max-width: 480px) {
    display: none;
  }
`;

export const NotificationCardBody = styled.div`
  width: 90%;
  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

export const NotificationCardInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 0.5rem;
  font-weight: 400;
`;

export const NotificationCardDate = styled.div`
  display: flex;
  justify-content: center;

  font-weight: 400;
  font-size: 0.7rem;

  padding-top: 0.5rem;
`;

export const NotificationCardType = styled.div`
  & > p {
    align-self: center;
  }
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  text-align: center;

  font-weight: bold;

  width: 50%;

  @media screen and (max-width: 480px) {
    width: auto;
    flex: 4;
  }
`;

export const NotificationCardIcons = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-between;

  padding-bottom: 2%;
  width: 10%;
`;

export const XHover = styled.div`
  position: absolute;
  right: 4%;
  top: 0;
  :hover {
    color: ${COLORS.RED};
  }
`;

export const WhatsAppHover = styled.div`
  :hover {
    color: ${COLORS.GREEN};
  }
`;

export const WrapperNotification = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 0.8rem;
  position: relative;
`;
