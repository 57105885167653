import Resizer from 'react-image-file-resizer';

export const resizeImage = (
  file: File,
  maxWidth = 800,
  maxHeight = 800,
  format = 'JPEG',
  quality = 100,
): Promise<File> => {
  return new Promise((resolve, reject) => {
    try {
      Resizer.imageFileResizer(
        file,
        maxWidth,
        maxHeight,
        format,
        quality,
        0,
        async (
          resizedBlob: string | File | Blob | ProgressEvent<FileReader>,
        ) => {
          if (typeof resizedBlob !== 'string' && resizedBlob instanceof Blob) {
            const resizedFile = new File([resizedBlob], file.name, {
              type: format,
              lastModified: Date.now(),
            });

            if (resizedFile.size > 1 * 1024 * 1024) {
              // se for maior do que 1MB
              const newQuality = quality * 0.8;
              try {
                const newCompressedFile = await resizeImage(
                  resizedFile,
                  maxWidth,
                  maxHeight,
                  format,
                  newQuality,
                );
                resolve(newCompressedFile);
              } catch (error) {
                reject(error);
              }
            } else {
              resolve(resizedFile);
            }
          }
        },
        'blob',
      );
    } catch (error) {
      reject(error);
    }
  });
};
