import { Controller, useForm } from 'react-hook-form';
import { useCallback, useState } from 'react';
import useLoginModal from '../../hooks/useLoginModal';
import { Button } from '../Button';
import { Input } from '../Input';
import { Modal } from '../Modal';
import styles from './login-modal.module.css';
import {
  authenticateAgentController,
  authenticateWorkshopController,
} from '../../pages/common/LandingPage/Pages/Login/controller';
import { AuthenticationResponse } from '../../pages/common/LandingPage/Pages/Login/interface';

interface FormValues {
  login: string;
  password: string;
}
type AccessType = 'workshop' | 'agent';

export const LoginModal = () => {
  const loginModal = useLoginModal();
  const [accessType, setAccessType] = useState<AccessType>('workshop');

  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      login: '',
      password: '',
    },
  });

  const onSubmit = async (formData: FormValues) => {
    const requestBody = {
      email: formData.login.trim(),
      password: formData.password,
    };

    let res: AuthenticationResponse | undefined;

    if (accessType === 'workshop') {
      res = await authenticateWorkshopController(requestBody);
    } else {
      res = await authenticateAgentController(requestBody);
    }

    if (res) {
      localStorage.setItem('@AutoCenter: accessToken', res.accessToken);
      localStorage.setItem('@AutoCenter: refreshToken', res.refreshToken);
      // localStorage.setItem('reload', 'true');
    }
    loginModal.onClose();
    window.location.reload();
  };

  const handleSingOut = useCallback(() => {
    window.location.href = '/page-login';
    localStorage.clear();
  }, []);

  const footer = (
    <footer className={styles.footer}>
      <Button
        handleClick={handleSubmit(onSubmit)}
        style={{
          fontSize: '1.25rem',
        }}
      >
        Enviar
      </Button>
    </footer>
  );

  return (
    <Modal
      isOpen={loginModal.isOpen}
      title="Revalide seus Dados"
      footer={footer}
    >
      <div className={styles['access-type-container']}>
        <span>Acessar como:</span>
        <div className={styles['access-type-selector']}>
          <button
            type="button"
            onClick={() => setAccessType('workshop')}
            className={accessType === 'workshop' ? styles.selected : ''}
          >
            Gestor
          </button>
          <button
            type="button"
            onClick={() => setAccessType('agent')}
            className={accessType === 'agent' ? styles.selected : ''}
          >
            Colaborador
          </button>
        </div>
      </div>
      <form className={styles.container}>
        <Controller
          control={control}
          name="login"
          render={({ field }) => (
            <Input {...field} label="Email, CPF ou CNPJ" />
          )}
        />
        <Controller
          control={control}
          name="password"
          render={({ field }) => (
            <Input {...field} type="password" label="Senha" />
          )}
        />
      </form>
      <Button
        handleClick={handleSingOut}
        variant="unstyled"
        style={{
          fontSize: '1.25rem',
          textDecoration: 'underline',
          color: 'var(--primary)',
          marginTop: '0.5rem',
        }}
      >
        Esqueci minha senha
      </Button>
    </Modal>
  );
};
