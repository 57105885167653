import api from '../../../../../../services/api/api';

export interface UpdateVehicleInput {
  vehicleId: string;
  name?: string;
  model?: string;
  year?: string;
  yearModel?: string;
  displacement?: string;
}
export interface UpdateVehicleKmInput {
  currentKm: number;
  vehicleId: string;
}
export interface TransferVehicleUpdateInput {
  vehicleId: string;
  userId: string;
}

export const updateVehicle = async (vehicle: UpdateVehicleInput) => {
  const { data } = await api.put('/vehicle/new', vehicle);
  return data;
};
export const updateVehicleKm = async ({
  currentKm,
  vehicleId,
}: UpdateVehicleKmInput) => {
  const { data } = await api.put('/vehicle/km', {
    currentKm,
    vehicleId,
  });
  return data;
};
export const transferVehicle = async (
  transferInput: TransferVehicleUpdateInput,
) => {
  const { data } = await api.put('/vehicle/transfer', transferInput);
  return data;
};
