import { handleError } from '../../../../../utils/handleError';
import { GetBudgetInput, getBudgetService } from './model';

export const getBudgetController = async ({ query = '' }: GetBudgetInput) => {
  try {
    const res = await getBudgetService({ query });
    return res;
  } catch (error) {
    handleError(error);
  }
};
