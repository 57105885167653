/* eslint-disable no-nested-ternary */
import { CaretDown } from '@phosphor-icons/react';
import Tooltip from '../../../../../shared/ToolTip';
import { dateOffset, formatDate } from '../../../../../utils/dateFunctions';
import { maskPhone } from '../../../../../services/helpers/mask';
import styles from './budget-item.module.css';
import { BudgetProps } from '../../../../../features/budget/interfaces';

interface Props {
  budget: BudgetProps;
}

export const BudgetItem = ({ budget }: Props) => {
  const handleText = (text: string) => {
    return (
      <span
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        {text.length > 15 ? `${text.slice(0, 15)}...` : text}
      </span>
    );
  };

  const date = formatDate(dateOffset(new Date(budget.created_at)));

  return (
    <>
      <div className={` ${styles.dropdown}`}>
        <div className={styles.icon}>
          <CaretDown size="1.5rem" />
        </div>
        <div>{budget.BUDGET ?? '---'}</div>
      </div>
      <Tooltip text={budget.name ? budget.name : 'Cliente Avulso'}>
        {handleText(budget.name ? budget.name : 'Cliente Avulso')}
      </Tooltip>
      <div>{budget?.plate || '----'}</div>
      <div className={styles.desktop}>
        <Tooltip
          text={budget.phone ? maskPhone(budget.phone) : 'Não Informado'}
        >
          {budget.phone ? maskPhone(budget.phone) : 'Não Informado'}
        </Tooltip>
      </div>
      <div>{date}</div>
    </>
  );

  return (
    <>
      <span>123</span>
      <span>Cliente</span>
      <span>Placa</span>
      <span>Contato</span>
      <span>Data</span>
    </>
  );
};
