import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { QuotationNotificationResponse } from './interface';
import {
  getQuotationNotificationController,
  updateQuotationNotificationController,
} from './controller';

export const useQuotationNotification = () => {
  const [selectedNotification, setSelectedNotification] =
    useState<QuotationNotificationResponse | null>(null);

  const { data: notifications = [], isLoading } = useQuery<
    QuotationNotificationResponse[],
    Error
  >({
    queryKey: ['quotationNotifications'],
    queryFn: () =>
      getQuotationNotificationController({
        page: 1,
        limit: 999,
      }),
    refetchInterval: 10 * 1000,
  });

  const queryClient = useQueryClient();

  const { mutate: readNotification } = useMutation(
    (id: string) => updateQuotationNotificationController(id),
    {
      onSuccess: (updatedNotification: QuotationNotificationResponse) => {
        queryClient.setQueryData<QuotationNotificationResponse[]>(
          ['quotationNotifications'],
          (oldNotifications = []) =>
            oldNotifications.map(notification =>
              notification.id_notification ===
              updatedNotification.id_notification
                ? updatedNotification
                : notification,
            ),
        );
      },
    },
  );

  const readQuotationNotification = () => {
    if (selectedNotification) {
      readNotification(selectedNotification.id_notification);
    }
  };

  return {
    notifications,
    setSelectedNotification,
    selectedNotification,
    readQuotationNotification,
    isLoading,
  };
};
