/* eslint-disable react/no-array-index-key */
import { useState } from 'react';
import { Column, MainColumn } from './_notifications';
import { NotificationCard } from './components/NotificationCard';
import { NotificationProps } from './model';
import { ReadModal } from './components/ReadModal/ReadModal';
import { DeleteModal } from './components/DeleteModal';
import { PageLayout } from '../../../shared/PageLayout';
import { useNotifications } from './useNotifications';
import { Loading } from '../../../shared/Loading';

export const Notifications = () => {
  const monthOptions = [
    'janeiro',
    'fevereiro',
    'março',
    'abril',
    'maio',
    'junho',
    'julho',
    'agosto',
    'setembro',
    'outubro',
    'novembro',
    'dezembro',
  ];

  const formatDate = (inDate?: Date) => {
    if (inDate) {
      const date = new Date(inDate).toLocaleString().split('/');
      const horaMinuto = date[2].substring(6, 11);
      const year = new Date(inDate).getFullYear().toString().slice(2, 4);
      return `${date[0].length > 1 ? date[0] : `0${date[0]}`}/${
        date[1].length > 1 ? date[1] : `0${date[1]}`
      }/${year} às ${horaMinuto}`;
    }
    const date = new Date().toLocaleString().split('/');
    const [year, time] = [...date[2].split(' ')];
    return `${date[0]} de ${monthOptions[+date[1] - 1]} de ${year} às ${time}`;
  };

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isReadModalOpen, setIsReadModalOpen] = useState(false);
  const [notificationToRead, setNotificationToRead] =
    useState<NotificationProps | null>(null);

  const {
    isLoading,
    notifications,
    selectedNotification,
    setSelectedNotification,
    removeNotification,
  } = useNotifications();

  const buildNotificationTitle = (type: string, userName: string) => {
    switch (type) {
      case 'master':
        return 'Autocenter Informa';
      case 'assessment':
        return `Avaliação para ${userName}`;
      default:
        return `Manutenção Preventiva de ${userName}`;
    }
  };

  const renderNotificationCard = (notification: NotificationProps) => {
    if (notification)
      return (
        <NotificationCard
          key={notification.id_notification}
          notification={notification}
          title={
            buildNotificationTitle(notification.type, notification.user?.name)
            // notification.type === 'master'
            //   ? 'Autocenter Informa'
            //   : `Manutenção Preventiva de ${notification.user?.name}`
          }
          date={formatDate(notification.updated_at)}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
          setIsReadModalOpen={setIsReadModalOpen}
          setNotificationToRead={setNotificationToRead}
          setNotificationToDelete={setSelectedNotification}
        />
      );
    return <div />;
  };

  const handleDeleteNotification = async () => {
    setIsDeleteModalOpen(false);
    if (selectedNotification?.id_notification) {
      await removeNotification(selectedNotification.id_notification);
      setSelectedNotification(null);
    }
  };

  return (
    <PageLayout.Root title="Notificações">
      <DeleteModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={handleDeleteNotification}
      />

      <ReadModal
        isOpen={isReadModalOpen}
        onClose={() => {
          setIsReadModalOpen(false);
          setNotificationToRead(null);
        }}
        notification={notificationToRead}
      />

      <MainColumn>
        <Column style={{ gap: '.5rem' }}>
          {/* Placeholder de carregamento */}
          {isLoading && notifications.length === 0 && (
            <LoadingPlaceholder itemCount={4} height="8rem" />
          )}

          {/* Mensagem quando não há notificações */}
          {!isLoading && notifications.length === 0 && (
            <p>Nenhuma notificação até o momento</p>
          )}

          {/* Renderização das notificações */}
          {!isLoading &&
            notifications.length > 0 &&
            notifications.map(renderNotificationCard)}

          <div style={{ minHeight: '5%' }} />
        </Column>
      </MainColumn>
    </PageLayout.Root>
  );
};

const LoadingPlaceholder = ({
  itemCount,
  height,
}: {
  itemCount: number;
  height: string;
}) => (
  <>
    {Array.from({ length: itemCount }).map((_, index) => (
      <Loading key={index} style={{ height }} />
    ))}
  </>
);
