/* eslint-disable no-useless-escape */
import * as yup from 'yup';
import { ERROR_MESSAGES } from '../../../../../../utils/validations/errorMessages';
import { validateKm } from '../../../../../../utils/validations/kmValidation';
import message from '../../../../../../utils/message';
import { validateLicensePlate } from '../../../../../../utils/validations/licensePLateValidation';

interface Props {
  km: string;
  currentKm: number;
  licensePlate: string;
  vehicle: string;
}

export const validateForm = async ({
  km,
  currentKm,
  licensePlate,
  vehicle,
}: Props) => {
  const convertedKm = km !== '' ? parseInt(km, 10) : null;
  const schema = yup.object().shape({
    licensePlate: yup
      .string()
      .required(ERROR_MESSAGES.requiredLicensePlate)
      .test(
        'license plate validation',
        ERROR_MESSAGES.invalidLicensePlate,
        value => validateLicensePlate(value || ''),
      ),
    vehicle: yup.string().required(ERROR_MESSAGES.required),
    km: yup
      .number()
      .nullable()
      .test(
        'min km value',
        `A km deve ser maior ou igual a ${currentKm}`,
        value => {
          if (value === null || (value && value >= currentKm)) {
            return true;
          }
          return false;
        },
      )
      .test('required min value', ERROR_MESSAGES.invalidKM, value =>
        validateKm((value || '').toString()),
      ),
  });
  try {
    await schema.validate({ km: convertedKm, licensePlate, vehicle });
    return true;
  } catch (err) {
    if (err instanceof yup.ValidationError) {
      message(err.errors[0], 'warning');
    }
    message(err, 'warning');
  }
};
