import { Route, Routes as Switch } from 'react-router-dom';
import PrivateRoute from '../private/routesPrivate';
import { AnswerQuotationProvider } from '../../contexts/answerQuotationContext';
import { AutoshopAgentQuotation } from '../../pages/common/Quotation/autoshop_agent';
import { QuotationProvider } from '../../contexts/quotationContext';
import { AgentWorkshopQuotation } from '../../pages/common/Quotation/workshop_agent';
import { Home } from '../../pages/agentQuotation/Home';
import { OrderServiceList } from '../../pages/workshop/OrderServiceList';
import UserEdit from '../../pages/common/UserEdit/UserEdit';

// import { Budget } from '../../pages/workshop/Budget';
import { ProfileEdit } from '../../pages/agentQuotation/ProfileEdit';
import { CreateOrderService } from '../../pages/common/OrderServiceCreate';

export const QuotationRoutes = () => (
  <Switch>
    <Route
      path="/home"
      element={
        <PrivateRoute>
          <Home />
        </PrivateRoute>
      }
    />
    <Route
      path="/order-service-list"
      element={
        <PrivateRoute>
          <OrderServiceList />
        </PrivateRoute>
      }
    />
    {/* <Route
      path="/budget"
      element={
        <PrivateRoute>
          <Budget />
        </PrivateRoute>
      }
    /> */}
    <Route
      path="/user-edit"
      element={
        <PrivateRoute>
          <UserEdit />
        </PrivateRoute>
      }
    />
    <Route
      path="/create-order-service/:id_budget"
      element={
        <PrivateRoute>
          <CreateOrderService />
        </PrivateRoute>
      }
    />
    <Route
      path="/create-order-service"
      element={
        <PrivateRoute>
          <CreateOrderService />
        </PrivateRoute>
      }
    />

    {/* autoshop agent routes */}
    <Route
      path="/agent-answer-quotation/"
      element={
        <PrivateRoute>
          <AnswerQuotationProvider>
            <AutoshopAgentQuotation />
          </AnswerQuotationProvider>
        </PrivateRoute>
      }
    />
    {/* workshop agent routes */}
    <Route
      path="/agent-new-quotation/"
      element={
        <PrivateRoute>
          <QuotationProvider>
            <AgentWorkshopQuotation />
          </QuotationProvider>
        </PrivateRoute>
      }
    />
    <Route
      path="/profile-edit"
      element={
        <PrivateRoute>
          <ProfileEdit />
        </PrivateRoute>
      }
    />
  </Switch>
);
