import * as yup from 'yup';
import { ERROR_MESSAGES } from '../../../../../../utils/validations/errorMessages';

const serviceSchema = yup.object().shape({
  name: yup.string().required(),
  description: yup.string().required(),
  total: yup.number().required(),
  codProd: yup.string().required(),
  quantity: yup.number().required(),
});

export const arraySchema = yup
  .array()
  .of(serviceSchema)
  .min(1, ERROR_MESSAGES.minServices);
