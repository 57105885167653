import * as yup from 'yup';
import {
  NewAddressInput,
  UpdateAddressInput,
  createAddress,
  createCustomer,
  updateAddress,
} from './model';
import message from '../../../../../../utils/message';
import { CreateCustomerInput } from './model';
import { ERROR_MESSAGES } from '../../../../../../utils/validations/errorMessages';
import {
  validateCNPJ,
  validateCPF,
} from '../../../../../../utils/validations/documentValidation';

export const createAddressController = async ({
  city = '',
  district = '',
  number = '',
  street = '',
  uf = '',
  zip = '',
}: NewAddressInput) => {
  const schema = yup.object().shape({
    city: yup.string(),
    district: yup.string(),
    number: yup.string(),
    street: yup.string(),
    uf: yup.string(),
    zip: yup.string(),
  });
  try {
    const newAddress = {
      city: city || '',
      district: district || '',
      number: number || '',
      street: street || '',
      uf: uf || '',
      zip: zip || '',
    };
    await schema.validate(newAddress, { abortEarly: false });
    const response = await createAddress(newAddress);
    return response;
  } catch (err) {
    if (err instanceof yup.ValidationError) {
      message(err.errors[0], 'warning');
    } else {
      message(err, 'error');
    }
  }
};
export const updateAddressController = async ({
  city = '',
  district = '',
  number = '',
  street = '',
  uf = '',
  zip = '',
  addressId,
}: UpdateAddressInput) => {
  const schema = yup.object().shape({
    addressId: yup.string().required('Não foi possível identificar o endereço'),
    city: yup.string(),
    district: yup.string(),
    number: yup.string(),
    street: yup.string(),
    uf: yup.string(),
    zip: yup.string(),
  });
  try {
    const newAddress = {
      city: city || '',
      district: district || '',
      number: number || '',
      street: street || '',
      uf: uf || '',
      zip: zip || '',
      addressId,
    };
    await schema.validate(newAddress, { abortEarly: false });
    const response = await updateAddress(newAddress);
    return response;
  } catch (err) {
    if (err instanceof yup.ValidationError) {
      message(err.errors[0], 'warning');
    } else {
      message(err, 'error');
    }
  }
};
export const createCustomerController = async ({
  // workshop_id,
  document,
  name,
}: CreateCustomerInput) => {
  const schema = yup.object().shape({
    name: yup.string().required(ERROR_MESSAGES.requiredName),
    document: yup
      .string()
      .required(ERROR_MESSAGES.requiredDocument)
      .test('document', ERROR_MESSAGES.invalidDocument, value => {
        if (!value) return false;
        const formattedDocument = value.replace(/[^\d]+/g, '');
        if (formattedDocument.length === 11) {
          return validateCPF(formattedDocument);
        }
        if (formattedDocument.length === 14) {
          return validateCNPJ(formattedDocument);
        }
        return false;
      }),
  });
  try {
    const createInput = {
      document: document.trim(),
      name: name.trim(),
    };
    await schema.validate(createInput);
    const response = await createCustomer(createInput);
    return response;
  } catch (err) {
    if (err instanceof yup.ValidationError) {
      message(err.errors[0], 'warning');
    } else {
      message(err, 'error');
    }
  }
};
