import { io, Socket } from 'socket.io-client';
import { baseURL } from './api';

let socketInstance: Socket | null = null;

export const getSocket = () => {
  if (!socketInstance) {
    socketInstance = io(baseURL, {
      transports: ['websocket'],
      withCredentials: true,
    });
    console.log('socket ok.');
  }
  return socketInstance;
};
