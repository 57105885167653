import { useEffect, lazy, useCallback, useState } from 'react';
import { ReportRoutes } from '../reports/routes';
import { CommonRoutes } from '../common/routes';
import Fallback from '../../shared/Fallback/Fallback';
import { NotificationProvider } from '../../contexts/notificationContext';
import { QuotationRoutes } from '../quotation/routes';
import { useAuth } from '../../hooks/useAuth';

const WorkshopRoutes = lazy(() => import('../workshop/routes'));
const FleetRoutes = lazy(() => import('../fleet/routes'));
const GasStationRoutes = lazy(() => import('../gasStation/routes'));

export const PrivateRoutes = () => {
  const [accessType, setAccessType] = useState<string | null>(null);
  const { workshop, user } = useAuth();

  // useEffect(() => {
  //   const shouldReload = localStorage.getItem('reload');

  //   if (shouldReload) {
  //     localStorage.removeItem('reload');
  //     window.location.reload();
  //   }
  // }, []);

  useEffect(() => {
    setAccessType(user?.role_name ?? '');
  }, [user?.role_name]);

  const handleAccessType = useCallback(() => {
    switch (accessType) {
      case 'agent':
        return (
          <>
            <ReportRoutes workshopData={workshop} />
            <QuotationRoutes />
            <CommonRoutes />
          </>
        );

      case 'gas_station':
        return (
          <>
            <ReportRoutes workshopData={workshop} />
            <GasStationRoutes />
            <CommonRoutes />
          </>
        );
      case 'fleet':
        return (
          <>
            <ReportRoutes workshopData={workshop} />
            <FleetRoutes />
            <CommonRoutes />
          </>
        );
      case 'workshop':
        return (
          <>
            <ReportRoutes workshopData={workshop} />
            <WorkshopRoutes />
            <CommonRoutes />
          </>
        );
      default:
        return <CommonRoutes />;
    }
  }, [accessType, workshop]);

  if (accessType === null) {
    return <Fallback />;
  }

  return <NotificationProvider>{handleAccessType()}</NotificationProvider>;
};
