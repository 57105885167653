import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  deleteNotificationController,
  getNotificationsController,
} from './controller';
import { NotificationProps } from './model';

export const useNotifications = () => {
  const [selectedNotification, setSelectedNotification] =
    useState<NotificationProps | null>(null);

  const { data: notifications = [], isLoading } = useQuery<
    NotificationProps[],
    Error
  >({
    queryKey: ['generalNotifications'],
    queryFn: () => getNotificationsController(),
    refetchInterval: 10 * 1000,
  });

  const queryClient = useQueryClient();

  const { mutate: removeNotification } = useMutation(
    (id: string) => deleteNotificationController(id),
    {
      onSuccess: (_, id) => {
        queryClient.setQueryData<NotificationProps[]>(
          ['generalNotifications'],
          (oldNotifications = []) =>
            oldNotifications.filter(
              notification => notification.id_notification !== id,
            ),
        );
      },
    },
  );

  return {
    notifications,
    setSelectedNotification,
    selectedNotification,
    removeNotification,
    isLoading,
  };
};
