import { Control, UseFormReset } from 'react-hook-form';
import { useEffect, useMemo } from 'react';
import { WarningCircle, WhatsappLogo } from '@phosphor-icons/react';
import styles from './answers-list.module.css';
import { AnswerQuotation } from '../../../../../../../../@types/interface';
import { Button } from '../../../../../../../../shared/Button';
import { useResponsive } from '../../../../../../../../hooks/useResponsive';
import { QuotationTableHeader } from '../QuotationTableHeader';
import { Answer } from '../Answer';
import { FormValues, formItemQuotation } from '../../hooks/useAnswerQuotation';
import useSelectedAnswer from '../../../../hooks/useSelectedAnswer';

interface Props {
  control: Control<FormValues, any>;
  reset: UseFormReset<FormValues>;
  handleAnswer: () => void;
  formQuotationItems: formItemQuotation[];
  handleWhatsappMessage: (quotation: AnswerQuotation | null) => void;
  addSubItem: (index: number) => void;
  removeSubItem: (indexItem: number, indexSubitem: number) => void;
  onClose: () => void;
}

export const AnswersList = ({
  control,
  reset,
  handleAnswer,
  formQuotationItems,
  handleWhatsappMessage,
  addSubItem,
  removeSubItem,
  onClose,
}: Props) => {
  const { answer } = useSelectedAnswer();

  useEffect(() => {
    if (answer) {
      const normalizedItems = answer?.quotation?.items_quotation?.map(item => ({
        item_quotation_id: item.id_item_quotation,
        description: item?.description ?? '',
        quantity: item?.quantity ?? '',
        informedReference: item?.reference ?? '',
        sub_items: [
          {
            reference: '',
            quantity_available: '',
            total: '0',
            brand: '',
          },
        ],
      }));
      reset({
        quotationId: answer.quotation_id,
        // comment: answer?.comment
        //   ? `${answer?.comment}\nPrazo de Entrega: `
        //   : 'Prazo de Entrega: ',
        items: normalizedItems,
      });
    }
  }, [answer, reset]);

  const { sizeMobile } = useResponsive();

  const whatsapp = useMemo(() => {
    return answer?.quotation?.workshop.whatsapp ?? null;
  }, [answer?.quotation?.workshop.whatsapp]);

  const answerInfo = useMemo(() => {
    return answer?.quotation?.items_quotation.length === 1
      ? `${
          answer?.quotation?.items_quotation.length || ''
        } item aguardando sua oferta`
      : `${
          answer?.quotation?.items_quotation.length || ''
        } itens aguardando sua oferta`;
  }, [answer?.quotation?.items_quotation.length]);

  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <h1 className={styles.title}>Itens:</h1>
        {!sizeMobile && (
          <div className={styles['answers-info']}>
            <WarningCircle size={24} weight="fill" /> {answerInfo}
          </div>
        )}
        <div>
          {sizeMobile ? (
            <div
              style={{
                alignContent: 'center',
                justifyContent: 'start',
                alignItems: 'center',
                fontWeight: 'bold',
              }}
            >
              {' '}
              Contato:
            </div>
          ) : null}
          {whatsapp ? (
            <Button
              handleClick={() => handleWhatsappMessage(answer)}
              style={{
                height: '1.5rem',
                backgroundColor: '#007a37',
              }}
            >
              <WhatsappLogo size={24} weight="fill" />
              Whatsapp
            </Button>
          ) : (
            <div
              style={{
                width: '100%',
                textAlign: 'end',
              }}
            >
              Nenhum Contato Disponível
            </div>
          )}
        </div>
      </div>
      <div className={styles['answers-list']}>
        <QuotationTableHeader />
        <Answer
          control={control}
          handleAnswer={handleAnswer}
          formQuotationItems={formQuotationItems}
          addSubItem={addSubItem}
          removeSubItem={removeSubItem}
          answer={answer}
          onClose={onClose}
        />
      </div>
    </div>
  );
};
