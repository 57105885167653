import { useCallback, useState } from 'react';
import { AnswerQuotation } from '../../../../../../../../@types/interface';
import { DesktopList } from './Desktop';
import { Modal } from '../../../../../../../../shared/Modal';
import useWatchingState from '../../../../../autoshop_agent/pages/NotAnsweredQuotations/hooks/useWatchingState';
import { useAnswerQuotation } from '../../../../../../../../contexts/answerQuotationContext';
import useSelectedAnswer from '../../../../hooks/useSelectedAnswer';
import { Button } from '../../../../../../../../shared/Button';

interface Props {
  answerList: AnswerQuotation[];
  handleRowClick: (answer: AnswerQuotation) => void;
  onScroll: () => void;
  isLoading: boolean;
  isEmpty?: boolean;
  notFound?: boolean;
}

export const AnswerList = (answerListProps: Props) => {
  const [agentModalOpen, setAgentModalOpen] = useState(false);
  const { changeWatchingState } = useWatchingState();
  const { updateAnswerQuotations } = useAnswerQuotation();
  const selectedAnswer = useSelectedAnswer();

  const removeAgentFromAnswerQuotation = useCallback(async () => {
    const res = await changeWatchingState(
      selectedAnswer.answer?.id_answer_quotation ?? '',
      'off',
    );
    if (res) {
      updateAnswerQuotations(res);
    }
    setAgentModalOpen(false);
  }, [
    changeWatchingState,
    selectedAnswer.answer?.id_answer_quotation,
    updateAnswerQuotations,
  ]);

  return (
    <>
      <Modal
        title="Vendedor Respondendo"
        isOpen={agentModalOpen}
        onClose={() => {
          setAgentModalOpen(false);
        }}
        footer={
          <div
            // className={styles['modal-actions']}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '1.5rem',
            }}
          >
            <Button handleClick={removeAgentFromAnswerQuotation}>
              Desvincular
            </Button>
            <Button
              handleClick={() => {
                setAgentModalOpen(false);
              }}
              variant="ghost"
            >
              Cancelar
            </Button>
          </div>
        }
      >
        <p>
          O vendedor{' '}
          <b>
            {selectedAnswer.answer?.agent_watch?.user?.name ??
              'não identificado'}
          </b>{' '}
          está respondendo essa cotação
        </p>
      </Modal>
      <DesktopList
        {...answerListProps}
        onOpenAgentModal={() => setAgentModalOpen(true)}
      />
    </>
  );
};
