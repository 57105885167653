import { X, WhatsappLogo } from 'phosphor-react';
import { Dispatch, SetStateAction, useState } from 'react';
import {
  NotificationCardContainer,
  NotificationCardDate,
  NotificationCardIcons,
  NotificationCardInfo,
  NotificationCardInfoContainer,
  NotificationCardIsRead,
  NotificationCardType,
  XHover,
  WhatsAppHover,
  WrapperNotification,
  NotificationIcon,
  NotificationCardBody,
} from '../../_notifications';

import exclamationPreventative from '../../../../../assets/icons/exclamationPreventativeMaintenance.svg';
import belPreventive from '../../../../../assets/icons/belPreventiveMaintenance.svg';
import errorPreventive from '../../../../../assets/icons/errorPreventiveMaintenance.svg';
import { handleReadNotification } from '../../controller';
import { sendWhatsappMessage } from '../../../../../utils/sendWhatsappMessage';
import { NotificationProps, User, NotificationPreventive } from '../../model';
import { Workshop } from '../../../../../@types/interface';
import { useAuth } from '../../../../../hooks/useAuth';

interface Props {
  notification: NotificationProps;
  title: string;
  date: string;
  setIsDeleteModalOpen: Dispatch<SetStateAction<boolean>>;
  setIsReadModalOpen: Dispatch<SetStateAction<boolean>>;
  setNotificationToRead: Dispatch<SetStateAction<NotificationProps | null>>;
  setNotificationToDelete: Dispatch<SetStateAction<NotificationProps | null>>;
}

const handleColor = (type: string) => {
  switch (type) {
    case 'master':
      return { color: '#1E1E1E' };
    case 'preventive_workshop':
      return { color: '#FBC02D' };
    case 'Erro':
      return { color: '#9D1816' };
    default:
      return { color: '#1E1E1E' };
  }
};

const handleImage = (type: string) => {
  switch (type) {
    case 'master':
      return belPreventive;
    case 'preventive_workshop':
      return exclamationPreventative;
    case 'Erro':
      return errorPreventive;
    default:
      return exclamationPreventative;
  }
};

const handleNotificationTitle = (notificationType: string) => {
  switch (notificationType) {
    case 'master':
      return 'Autocenter Informa';
    case 'assessment':
      return `Nova Avaliação`;
    default:
      return `Manutenção Preventiva`;
  }
};

export const formatTextReadMore = (text: string, limit?: number) => {
  if (limit == null) limit = 20;
  if (text && text.length > limit) {
    return (
      <div>
        <span>{text.trim().substring(0, limit)}... </span>
        <span style={{ color: 'rgb(2 80 181)' }}>ler mais</span>
      </div>
    );
  }
  return text;
};

export const handlePreventiveMessage = (
  user: User,
  preventive: NotificationPreventive | null,
  workshop: Workshop | null,
) => {
  if (preventive) {
    if (preventive.sent_before_date) {
      // return `Olá! ${user.name}. ${fantasy_name} informa: sua revisão para ${preventive.type?.name} do veículo ${preventive.vehicle.license_plate} - ${preventive.vehicle.name} está próxima.`;
      return `Olá, ${user.name}! ${
        workshop?.fantasy_name ?? ''
      } está acompanhando seu veículo. A revisão para o item: ${
        preventive.type?.name
      } do seu ${preventive.vehicle.name} (placa: ${
        preventive.vehicle.license_plate
      }) está se aproximando. Entre em contato e não perca a chance de deixar tudo em dia!`;
    }
    if (preventive.sent_on_date) {
      // return `Olá! ${user.name}. ${workshop?.fantasy_name ?? ''} informa: sua revisão para ${preventive.type?.name} do veículo ${preventive.vehicle.license_plate} - ${preventive.vehicle.name} chegou. Vamos agendar?`;
      return `Olá, ${user.name}! ${
        workshop?.fantasy_name ?? ''
      } está acompanhando seu veículo. A revisão para item: ${
        preventive.type?.name
      } do seu ${preventive.vehicle.name} (placa: ${
        preventive.vehicle.license_plate
      }) chegou. Entre em contato para agendar agora!`;
    }
    if (preventive.sent_after_date) {
      // return `Olá! ${user.name}. ${workshop?.fantasy_name ?? ''} informa: sua revisão para ${preventive.type?.name} do veículo ${preventive.vehicle.license_plate} - ${preventive.vehicle.name} venceu. Vamos agendar?`;
      return `Olá, ${user.name}! ${
        workshop?.fantasy_name ?? ''
      } está acompanhando seu veículo. A revisão para item: ${
        preventive.type?.name
      } do seu ${preventive.vehicle.name} (placa: ${
        preventive.vehicle.license_plate
      }) já passou da data recomendada. Vamos marcar um horário para colocar tudo em ordem?`;
    }
  }
  return `Olá! ${user.name}. Aqui é ${
    workshop?.fantasy_name ?? ''
  }. Vamos marcar sua manutenção preventiva?`;
};

export const NotificationCard = ({
  notification,
  title,
  date,
  setIsDeleteModalOpen,
  setIsReadModalOpen,
  setNotificationToRead,
  setNotificationToDelete,
}: Props) => {
  const { message, type, user, read, id_notification, preventive } =
    notification;
  const [isRead, setIsRead] = useState(read);
  const { workshop } = useAuth();

  const handleDelete = () => {
    setIsDeleteModalOpen(true);
    setNotificationToDelete(notification);
  };

  const handleRead = async () => {
    if (isRead === false) {
      setIsRead(true);
      await handleReadNotification(id_notification);
    }
  };

  const handleOpenReadModal = () => {
    setIsReadModalOpen(true);
    setNotificationToRead(notification);
  };

  const renderWhatsapp = () => {
    if (type === 'master') {
      return (
        <WhatsAppHover>
          <WhatsappLogo
            style={{
              cursor: 'pointer',
            }}
            size={25}
            weight="light"
            onClick={() =>
              sendWhatsappMessage(
                `Olá! Autocenter. Gostaria de ajuda sobre ${message} recebida.`,
                '+5587991080605',
              )
            }
          />
        </WhatsAppHover>
      );
    }

    return (
      <WhatsAppHover>
        <WhatsappLogo
          style={{
            cursor: 'pointer',
          }}
          size={25}
          weight="light"
          onClick={() =>
            sendWhatsappMessage(
              handlePreventiveMessage(user, preventive, workshop),
              user.phone,
            )
          }
        />
      </WhatsAppHover>
    );
  };

  return (
    <NotificationCardContainer onClick={handleRead}>
      <NotificationIcon>
        <img src={handleImage(type)} alt="logo" />
      </NotificationIcon>

      <NotificationCardBody>
        <WrapperNotification>
          <NotificationCardType style={handleColor(type)}>
            <p>
              {handleNotificationTitle(type)}
              {/* {type === 'master'
                ? 'Autocenter Informa'
                : 'Manutenção Preventiva'} */}
            </p>
          </NotificationCardType>

          <NotificationCardIsRead style={{ display: isRead ? 'none' : 'flex' }}>
            <div>Novo</div>
          </NotificationCardIsRead>
          <XHover>
            <X style={{ cursor: 'pointer' }} onClick={handleDelete} />
          </XHover>
        </WrapperNotification>

        <WrapperNotification>
          <NotificationCardInfoContainer onClick={handleOpenReadModal}>
            <NotificationCardInfo>
              <span>{formatTextReadMore(message, 140)}</span>
            </NotificationCardInfo>
            <NotificationCardDate>
              <p>{date}</p>
            </NotificationCardDate>
          </NotificationCardInfoContainer>

          <NotificationCardIcons>{renderWhatsapp()}</NotificationCardIcons>
        </WrapperNotification>
      </NotificationCardBody>
    </NotificationCardContainer>
  );
};
