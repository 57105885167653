/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useMemo } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import UserEdit from '../../pages/common/UserEdit/UserEdit';
import {
  getObjectFromLocal,
  saveObjectLocally,
} from '../../utils/localStorage';
import { useAuth } from '../../hooks/useAuth';
import { isAuthenticated } from '../../services/api/auth';

interface Props {
  children: React.ReactNode;
}

export const PrivateRoute = ({ children }: Props) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const answerId = queryParams.get('answerId');

  const { user } = useAuth();

  const isPasswordUpdateRequired = useMemo(
    () => user?.is_first_access_after_recovery || user?.is_first_access,
    [user?.is_first_access_after_recovery, user?.is_first_access],
  );

  // Salva o answerId no localStorage se existir
  useEffect(() => {
    if (answerId && answerId.length > 0 && !getObjectFromLocal('answerId')) {
      saveObjectLocally('answerId', answerId);
    }
  }, [answerId]);

  // Renderiza a página de atualização de senha, se necessário
  if (isPasswordUpdateRequired) {
    return (
      <UserEdit messageToUpdate="Por favor, para prosseguir, mude a sua senha" />
    );
  }

  if (!isAuthenticated()) {
    return <Navigate to="/page-login" replace />;
  }

  return <>{children}</>;
};

export default PrivateRoute;
