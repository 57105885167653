import Lottie from 'lottie-react';
import loadingAnimation from '../../assets/carro.json';

const Fallback: React.FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        width: '100vw',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {/* <p>Loading...</p> */}
      <Lottie animationData={loadingAnimation} />
    </div>
  );
};

export default Fallback;
