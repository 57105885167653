import logo from '../assets/logo_autocenter.svg';
import logoNatal from '../assets/logo_autocenter_natal.svg';

export const getLogo = () => {
  const today = new Date();
  const month = today.getMonth(); // 0 = Janeiro, 11 = Dezembro
  const day = today.getDate();

  // Verifica se estamos entre 15 de Dezembro e 15 de Janeiro
  if ((month === 11 && day >= 15) || (month === 0 && day <= 15)) {
    return logoNatal;
  }
  return logo;
};
