import * as yup from 'yup';
import message from '../../../../../../utils/message';
import {
  TransferVehicleUpdateInput,
  UpdateVehicleInput,
  transferVehicle,
  updateVehicle,
  updateVehicleKm,
} from './model';
import { ERROR_MESSAGES } from '../../../../../../utils/validations/errorMessages';

export const updateVehicleController = async ({
  name,
  model,
  vehicleId,
  year,
  displacement,
  yearModel,
}: UpdateVehicleInput) => {
  const vehicleToUpdate: UpdateVehicleInput = {
    vehicleId,
  };
  if (yearModel) {
    vehicleToUpdate.yearModel = yearModel;
  }
  if (name) {
    vehicleToUpdate.name = name;
  }
  try {
    const schema = yup.object().shape({
      name: yup.string(),
      vehicleId: yup.string().required('Veículo não identificado'),
      yearModel: yup
        .string()
        .min(4, ERROR_MESSAGES.invalidYear)
        .test('yearModel', ERROR_MESSAGES.invalidYear, value => {
          // se ano / modelo
          if (value && value.length > 4) {
            const years = value.split('/');
            if (years.length !== 2) return false;
            if (years[0].trim().length === 4 && years[1].trim().length === 4)
              return true;
            return false;
          }
          // vazio ou somente o ano
          return true;
        }),
    });
    await schema.validate(vehicleToUpdate, { abortEarly: false });
    const response = await updateVehicle(vehicleToUpdate);
    return response;
  } catch (err) {
    if (err instanceof yup.ValidationError) {
      message(err.errors[0], 'warning');
    } else {
      message(err, 'error');
    }
  }
};

export const updateKmController = async (km: number, vehicleId: string) => {
  const schema = await yup.object().shape({
    km: yup
      .number()
      .test('km', ERROR_MESSAGES.invalidKM, value => {
        if (!value) return true;
        if (typeof value !== 'number') return false;
        if (value < 100) return false;
        if (value > 999999) return false;
        return true;
      })
      .nullable(),
    vehicleId: yup.string(),
  });
  try {
    await schema.validate({ km, vehicleId }, { abortEarly: false });
    if (km !== 0) {
      const response = await updateVehicleKm({ currentKm: km, vehicleId });
      return response;
    }
  } catch (err) {
    if (err instanceof yup.ValidationError) {
      message(err.errors[0], 'warning');
    } else {
      message(err, 'error');
    }
  }
};

export const transferVehicleController = async ({
  userId,
  vehicleId,
}: TransferVehicleUpdateInput) => {
  const schema = yup.object().shape({
    userId: yup
      .string()
      .required('Não foi possível vincular o novo proprietário'),
    vehicleId: yup.string().required('Não foi possível vincular o veículo'),
  });
  try {
    const transferInput = { userId, vehicleId };
    await schema.validate(transferInput);
    await transferVehicle(transferInput);
  } catch (err) {
    if (err instanceof yup.ValidationError) {
      message(err.errors[0], 'warning');
    } else {
      message(err, 'error');
    }
  }
};
