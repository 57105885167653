import { NavigateFunction } from 'react-router-dom';
import { AnyAction, Dispatch } from 'redux';
import React, { SetStateAction } from 'react';
import { Repair } from '../../common/History/interfaces';
import { setOrderService } from '../../../features/orderService/orderService-slice';
import { saveObjectLocally } from '../../../utils/localStorage';
// import { visitorId } from '../../../routes/routes';

interface Props {
  dispatch: Dispatch<AnyAction>;
  navigate: NavigateFunction;
}

export const useRepairActions = ({ dispatch, navigate }: Props) => {
  const handleViewOS = (repair: Repair, query: string) => {
    dispatch(setOrderService(repair));
    navigate(`/order-service-report?orderServiceId=${repair?.id_repair ?? ''}`);
    localStorage.setItem('@autocenter: search', query);
  };
  const handleUpdateOS = (repair: Repair) => {
    saveObjectLocally('@local-order-service', repair);

    navigate(`/create-order-service`);
  };

  const handleDeleteOS = (
    repair: Repair,
    setSelectedRepairToRemove: React.Dispatch<SetStateAction<Repair | null>>,
  ) => {
    // if (userLocal && userLocal.id_user !== visitorId) {
    setSelectedRepairToRemove(repair);
    // }
  };
  const handleHistory = (repair: Repair) => {
    if (repair.vehicle && repair.vehicle.license_plate)
      navigate(`/history?licensePlate=${repair.vehicle.license_plate}`);
  };

  const handleSelectRepair = (
    repair: Repair,
    setSelectedRepair: React.Dispatch<SetStateAction<Repair | null>>,
  ) => {
    setSelectedRepair(currentRepair => {
      if (currentRepair && repair.id_repair === currentRepair.id_repair) {
        return null;
      }
      return repair;
    });
  };

  return {
    handleViewOS,
    handleUpdateOS,
    handleDeleteOS,
    handleHistory,
    handleSelectRepair,
  };
};
