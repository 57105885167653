import styled, { keyframes } from 'styled-components';
import { COLORS } from '../../../../../styles/colors';

const fade = keyframes`
  0%{
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
`;

interface ScrollViewProps {
  height: number;
}

export const Container = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #49505762;
`;

export const ContentModal = styled.div`
  z-index: 2;

  width: 60%;
  min-height: 60%;
  height: auto;
  background: white;
  animation-name: ${fade};
  animation-duration: 1s;

  border-radius: 5px;

  @media only screen and (max-width: 768px) {
    width: 90%;
    max-height: 60%;
  }
`;

export const Header = styled.div`
  width: 100%;
  height: 40%;
  color: ${COLORS.WHITE};
  background: #6bb56a;
  font-size: 1.2rem;
  font-weight: 900;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1%;

  gap: 1rem;

  @media only screen and (max-width: 768px) {
    font-size: 1rem;
    padding: 3%;

    margin-bottom: 2%;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 2%;
  padding-right: 2%;
  font-size: 1rem;

  @media only screen and (max-width: 768px) {
    padding-left: 4%;
    padding-right: 4%;
  }
`;

export const MainRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
  margin-top: 1rem;

  @media only screen and (max-width: 768px) {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
  @media only screen and (max-width: 500px) {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

export const Content = styled.div`
  width: 100%;
  height: 60%;

  display: flex;
  flex-direction: column;
  font-size: 1.1rem;
`;

export const BoldText = styled.div`
  font-weight: 700;
`;

export const ContactBtn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background: #6bb56a;
  color: ${COLORS.WHITE};
  border-radius: 15px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  padding: 1%;
  width: 25%;

  cursor: pointer;

  @media only screen and (max-width: 1024px) {
    width: 30%;
  }
  @media only screen and (max-width: 600px) {
    width: 65%;
  }
`;

export const ScrollView = styled.div<ScrollViewProps>`
  height: 150px;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
`;
