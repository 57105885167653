import { Eye, WhatsappLogo } from 'phosphor-react';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './notification-modal.module.css';
import { ModalContent, ModalTitle } from '../../Finance/components/Modal';
import { QuotationNotificationResponse } from '../interface';
import { getNotificationLink } from '../../../../utils/getNotificationLink';
import { getNotificationWorkshop } from '../../../../utils/getNotificationWorkshop';
import { sendWhatsappMessage } from '../../../../utils/sendWhatsappMessage';
import { useAuth } from '../../../../hooks/useAuth';

interface Props {
  onClose: () => void;
  isOpen: boolean;
  notification: QuotationNotificationResponse | null;
}

export const NotificationModal = ({ isOpen, notification, onClose }: Props) => {
  const navigate = useNavigate();
  const { isAgent } = useAuth();

  const notificationWorkshop = useMemo(
    () => getNotificationWorkshop(notification),
    [notification],
  );

  const redirectLink = useMemo(
    () => getNotificationLink(notification),
    [notification],
  );

  const handleRedirect = useCallback(() => {
    const basePath = isAgent ? '/agent' : '';

    navigate(`${basePath}${redirectLink}`);
  }, [isAgent, navigate, redirectLink]);

  const handleRedirectToWhatsapp = useCallback(() => {
    sendWhatsappMessage('', notificationWorkshop?.phone ?? '');
  }, [notificationWorkshop?.phone]);

  return (
    <ModalContent
      onClose={onClose}
      open={isOpen}
      ContentClassName={styles['modal-content']}
    >
      <ModalTitle>
        {notification?.title ?? 'Título de notificação não encontrada'}
      </ModalTitle>
      <div className={styles['modal-message']}>
        {notification?.message ?? 'Mensagem de notificação não encontrada'}
      </div>
      <div className={styles.actions}>
        {redirectLink && (
          <button type="button" onClick={handleRedirect}>
            <Eye size={32} />
            visualizar
          </button>
        )}
        {notificationWorkshop && (
          <button type="button" onClick={handleRedirectToWhatsapp}>
            <WhatsappLogo size={32} />
            ir para whatsapp
          </button>
        )}
      </div>
    </ModalContent>
  );
};
