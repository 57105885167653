/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/button-has-type */
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { IService } from '../../@types/interface';
import { EditableRow } from './EditableRow';
import { Container, Table } from './_editableTable';

interface ChildServiceProps extends IService {
  unit: number | null;
}
interface EditableTableProps {
  setStatus?: Dispatch<SetStateAction<string>>;
  setServices: Dispatch<SetStateAction<IService[]>>;
  services: IService[];
}
export const EditableTable = ({
  setStatus,
  setServices,
  services = [],
}: EditableTableProps) => {
  const initialValue = services.map((item, index) => ({
    ...item,
    cod_prod: `${index + 1}`,
    unit: item.total / item.quantity,
  }));
  const [childServices, setChildServices] =
    useState<ChildServiceProps[]>(initialValue);
  const addRow = () => {
    const lastIndex = childServices.length - 1;
    const lastId = parseInt(`${childServices[lastIndex]?.cod_prod}`, 10) || 0;
    setChildServices([
      ...childServices,
      {
        cod_prod: `${lastId + 1}`,
        name: '',
        description: '',
        quantity: 0,
        total: 0,
        unit: 0,
      },
    ]);
  };
  useEffect(() => {
    if (services.length === 0) addRow();
  }, []);
  const removeRow = (itemId: string) => {
    const editedData = childServices
      .filter(service => {
        if (service.cod_prod !== itemId) {
          return service;
        }
      })
      .map((item, index) => ({ ...item, cod_prod: `${index + 1}` }));

    // reorder index after remove
    setChildServices(editedData);
    setServices(
      childServices.map(service => ({
        name: service.name,
        cod_prod: service.cod_prod,
        description: service.description,
        quantity: service.quantity,
        total: service.total,
      })),
    );
  };
  useEffect(() => {
    setServices(
      childServices.map(service => ({
        name: service.name,
        cod_prod: service.cod_prod,
        description: service.description,
        quantity: service.quantity,
        total: service.total,
      })),
    );
    if (setStatus) setStatus('0');
  }, [childServices]);
  useEffect(() => {
    console.log('serviços iniciais: ', services);
  }, []);
  return (
    <Container>
      <Table>
        <thead>
          <tr>
            <th>Item</th>
            <th>Produto/Serviço</th>
            <th>Qtd.</th>
            <th>Unit.</th>
            <th>Subtotal</th>
            <th> </th>
          </tr>
        </thead>
        <tbody>
          {childServices.map(service => (
            <EditableRow
              key={service.cod_prod}
              handleRemove={removeRow}
              setChildServices={setChildServices}
              services={childServices}
              cod_prod={service.cod_prod}
              description={service.description}
              name={service.name}
              quantity={service.quantity}
              total={service.total}
              unit={service.unit}
            />
          ))}
        </tbody>
      </Table>
      <button
        className="addBtn"
        onClick={() => {
          addRow();
        }}
      >
        + adicionar novo item
      </button>
    </Container>
  );
};
