import { AnswerQuotation } from '../../../../../../../../@types/interface';
import { DesktopList } from './Desktop';

interface Props {
  answerList: AnswerQuotation[];
  handleRowClick: (answer: AnswerQuotation) => void;
  onScroll: () => void;
  isLoading: boolean;
  isEmpty?: boolean;
  notFound?: boolean;
}

export const AnswerList = (answerListProps: Props) => {
  return <DesktopList {...answerListProps} />;
};
