import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  Input,
  Radio,
  Row,
  Text,
  TextArea,
} from 'native-base';
import { Controller, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { AiOutlineLeft } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import pixel from '../../../../../../styles/size';
import { EditableTable } from '../../../../../../shared/EditableTable/EditableTable';
import {
  Banner,
  CheckListRequest,
  ChecklistInput,
  IService,
} from '../../../../../../@types/interface';
import { FormValues, RepairProps, ServicesFormProps } from './interfaces';
import { useAppSelector } from '../../../../../../hooks/useAppSelector';
import {
  addOrderServiceReducer,
  setChecklist,
  updateOrderServiceReducer,
} from '../../../../../../features/orderService/orderService-slice';
import api from '../../../../../../services/api/api';
import message from '../../../../../../utils/message';
import { arraySchema } from './schema';
import { AlertModal } from '../../../../../../shared/AlertModal';
import { formatServiceTable } from '../../../../../../utils/formatters/serviceFormat';
import { SendOSModal } from '../../../../../../shared/SendOSModal';
import { Repair } from '../../../../History/interfaces';
import { ChecklistForm } from '../../../../../../shared/CheckListForm';
import { validateSchema } from '../../../../../../utils/validations/validateSchema';
import { handleCreateRepair, handleUpdateRepair } from './controller';
import { fetchStatusOptions } from './model';
import {
  getObjectFromLocal,
  removeObjectFromLocal,
  saveObjectLocally,
} from '../../../../../../utils/localStorage';
import { COLORS } from '../../../../../../styles/colors';
import { VisitorModal } from '../../../../../../shared/VisitorModal';
import { handleErrorMessage } from '../../../../../../utils/handleErrorMessage';
import { Modal } from '../../../../../../shared/Modal';
import { MainComponent } from '../../../../../../shared/MainComponent/MainComponent';
import { Header } from '../../../../../../shared/Header';
import { ProgressBar } from '../../../../../../shared/ProgressBar';
import styles from './service-form.module.css';

const formControlProps = {
  w: { base: pixel(830, 1080), xl: pixel(730, 1920) },
  mb: '2rem',
};
const buttonProps = {
  variant: 'outline',
  h: '10',
  w: '6rem',
  borderRadius: '9px',
};

export const ServicesForm = ({
  setPage,
  isSeparateComponent,
  isSeparateCustomer,
}: ServicesFormProps) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const localOrderService: Repair | null = getObjectFromLocal(
    '@local-order-service',
  );

  const checklistFromState = useAppSelector(
    state => state.orderServices.checklistInput,
  );

  const [services, setServices] = useState<IService[]>(
    localOrderService?.child_services || [],
  );

  const [status, setStatus] = useState<string>('0');
  const [subtotal, setSubtotal] = useState<number>(0);
  const [discount, setDiscount] = useState<string>('');
  const [statusOptions, setStatusOptions] = useState<string[]>([]);
  const [repairId, setRepairId] = useState<string>('');
  const [showModal, setShowModal] = useState(false);
  const [showAdviceModal, setShowAdviceModal] = useState(false);
  const [isSendOSModalOpen, setIsSendOSModalOpen] = useState(false);
  const [sendOSModalStatus, setSendOSModalStatus] = useState<
    'sending' | 'error' | 'success'
  >('sending');
  const [isCheckListOpen, setIsCheckListOpen] = useState(false);
  const [checklistData, setChecklistData] = useState<ChecklistInput[]>([]);
  const [checklistId, setChecklistId] = useState('');
  const [localOrderServiceData, setLocalOrderServiceData] =
    useState<Repair | null>(null);
  const [bannersToRemove, setBannersToRemove] = useState<string[]>([]);
  const [uploadErrors, setUploadErrors] = useState<number[]>([]);
  const [isVisitorModalOpen, setIsVisitorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);

  const { control, watch, reset } = useForm<FormValues>({
    defaultValues: {
      comment: '',
      defect: '',
      discount: '',
      total: '',
    },
  });
  const { comment, defect } = watch();

  // REPAIR
  const handleStatusOptions = async () => {
    const orderServiceStatusOptions = await fetchStatusOptions();

    if (orderServiceStatusOptions)
      setStatusOptions(
        orderServiceStatusOptions.map(statusOption => statusOption.id_status),
      );
  };

  const createRepair = async (repair: RepairProps) => {
    const response = await handleCreateRepair(
      isSeparateComponent,
      isSeparateCustomer,
      repair,
    );

    if (response) {
      setRepairId(response.repair.id_repair);
      dispatch(addOrderServiceReducer([{ ...response.repair }]));
      setSendOSModalStatus('success');
    } else {
      setStatus('0');
      setSendOSModalStatus('error');
      handleSave();
    }
  };

  const updateRepair = async (repair: RepairProps) => {
    const response = await handleUpdateRepair(
      isSeparateComponent,
      isSeparateCustomer,
      {
        ...repair,
        description: localOrderServiceData?.description || '',
        phone: localOrderServiceData?.phone || '',
        userId: localOrderServiceData?.user_workshop?.id_users_Workshop || '',
      },
      repairId,
    );

    if (response) {
      dispatch(updateOrderServiceReducer(response));
      setSendOSModalStatus('success');
    } else {
      setStatus('0');
      setSendOSModalStatus('error');
      handleSave();
    }
  };

  const handleSubmit = async () => {
    setIsSendOSModalOpen(true);
    try {
      const checklistIdResponse = await handleSubmitChecklist();
      if (localOrderServiceData) {
        const {
          phone,
          name,
          description,
          km,
          vehicle_id,
          user_workshop,
          address,
          email,
        } = localOrderServiceData;

        const formattedServices = formatServiceTable(services);

        if (formattedServices) {
          formattedServices.forEach(service => {
            if (!service.name || !service.quantity || !service.total) {
              throw new Error(
                `Verifique o produto/serviço: ${
                  service.name || service.codProd
                }`,
              );
            }
          });
        }

        const repairContent: RepairProps = {
          phone: phone || '',
          name: name || '',
          description: description || '',
          comment,
          total: subtotal - parseFloat(discount || '0') || 0,
          defect: defect || 'não informado',
          childServices: formattedServices,
          discount: parseFloat(discount) || 0,
          statusId: statusOptions[parseInt(status, 10) - 1],
          userId: user_workshop.user.id_user || '',
          vehicleId: vehicle_id || '',
        };
        if (km) {
          repairContent.km = km;
        }
        if (email) {
          repairContent.email = email;
        }
        if (address && address.id_address) {
          repairContent.addressId = address.id_address;
        }
        if (checklistIdResponse || checklistId.length > 0) {
          repairContent.checklistId = checklistIdResponse || checklistId;
        }
        const validation = validateSchema(
          arraySchema,
          repairContent.childServices,
        );
        if (typeof validation === 'boolean' && validation) {
          if (repairId.length === 0) {
            await createRepair(repairContent);
          } else {
            await updateRepair(repairContent);
          }
        } else if (typeof validation === 'string') {
          message(validation, 'warning');
        }
      }
    } catch (error) {
      console.error(error);
      setIsSendOSModalOpen(false);
      setErrorMessage(handleErrorMessage(error));
      setShowErrorModal(true);
      setStatus('0');
    }
  };

  // CHECKLIST
  const createChecklist = async (checklistRequest: ChecklistInput[]) => {
    try {
      const formData = new FormData();
      if (checklistRequest[0].file) {
        formData.append('file', checklistRequest[0].file);
      }
      const { data } = await api.post<CheckListRequest>(
        '/checklist',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );
      return data;
    } catch (err) {
      console.error(err);
    }
  };
  const removeBanner = async (id_banner: string) => {
    try {
      await api.delete(`/banner/${id_banner}`);
    } catch (err) {
      console.error(err);
    }
  };
  const createBanner = async (file: File, checklist_id: string) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('checklistId', checklist_id);
      const { data } = await api.post<Banner>('/banner/checklist', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return data.id_banner;
    } catch (err) {
      console.error(err);
    }
  };
  const handleSubmitChecklist = async () => {
    let checklistIdResponse = '';
    let updatedChecklist = checklistData;
    if (checklistId.length === 0) {
      const checklistResponse = await createChecklist(checklistData);

      checklistIdResponse = checklistResponse?.id_checklist || '';
      setChecklistId(checklistIdResponse);

      updatedChecklist =
        checklistData.map((item, index) => {
          if (index === 0)
            return {
              ...item,
              file: null,
              id_banner: checklistResponse?.banners[0]?.id_banner || '',
            };
          return item;
        }) || [];
    }

    await Promise.all(
      bannersToRemove.map(async (item, index) => {
        if (item.length > 0) {
          try {
            await removeBanner(item);
          } catch (error) {
            setUploadErrors(prevErrors => [...prevErrors, index]);
            console.error(error);
          }
        }
      }),
    );

    updatedChecklist = await Promise.all(
      updatedChecklist.map(async (item, index) => {
        if (item.file) {
          try {
            const id_banner =
              (await createBanner(
                item.file,
                checklistId || checklistIdResponse,
              )) || '';
            return { ...item, file: null, id_banner };
          } catch (error) {
            setUploadErrors(prevErrors => [...prevErrors, index]);
            console.error(error);
            return item;
          }
        }
        return item;
      }),
    );

    setChecklistData(updatedChecklist);
    return checklistId || checklistIdResponse;
  };

  // LOGICA DOS DADOS
  const handleDiscountChange = (value: string) => {
    value = value.replace(',', '.');
    value = value.replace(/[^\d.]+/g, '').replace(/(\.)+/g, '.');

    setDiscount(value);
  };

  const handleSave = () => {
    const localOrderServiceToSave = {
      ...localOrderServiceData,
      id_repair: repairId,
      comment,
      defect,
      discount: parseFloat(discount || ''),
      child_services: services,
    };
    dispatch(setChecklist(checklistData));
    saveObjectLocally('@local-order-service', localOrderServiceToSave);
  };

  // const handlePdf = () => {
  //   if (localOrderServiceData) {
  //     const html = orderServicePdf({
  //       ...localOrderServiceData,
  //     });
  //     if (isWebView === '1') {
  //       const data = {
  //         name: 'pdf',
  //         html,
  //       };
  //       window.ReactNativeWebView.postMessage(JSON.stringify(data));
  //     } else {
  //       handleHtmlToPdf(
  //         html,
  //         `Autocenter-${localOrderServiceData?.name || 'avulso'}`,
  //       );
  //     }
  //   }
  // };

  // USE EFFECTS
  useEffect(() => {
    setSubtotal(
      services
        .map(service => service.total)
        .reduce((acc, next) => acc + next, 0),
    );
  }, [services]);

  useEffect(() => {
    setStatus('0');
  }, [subtotal, discount, comment, defect]);

  useEffect(() => {
    setLocalOrderServiceData(localOrderService);
    if (localOrderService) {
      const { id_repair, child_services, checklist_id } = localOrderService;
      const discountValue =
        localOrderService?.discount && localOrderService.discount > 0
          ? localOrderService?.discount?.toString()
          : '';
      setChecklistId(checklist_id || '');
      setDiscount(discountValue);
      setRepairId(id_repair || '');
      setServices(child_services || []);
      reset({
        comment: localOrderService.comment || '',
        defect: localOrderService.defect || '',
      });
    }
    handleStatusOptions();
  }, []);

  useEffect(() => {
    if (status === '1') {
      handleSubmit();
    }
    if (status === '2') {
      setShowModal(true);
    }
  }, [status]);

  useEffect(() => {
    if (checklistFromState.length === 0) {
      if (
        localOrderServiceData &&
        localOrderServiceData.checklist &&
        localOrderServiceData.checklist.banners.length > 0
      ) {
        const checklistFromOS = localOrderServiceData.checklist.banners.map(
          banner => {
            const { id_banner, img_url } = banner;
            return {
              id_banner,
              img_url,
              file: null,
            };
          },
        );
        setIsCheckListOpen(true);
        setChecklistData(checklistFromOS);
      }
    } else {
      setIsCheckListOpen(true);
      setChecklistData(checklistFromState);
    }
  }, [checklistFromState, localOrderServiceData]);

  return (
    <MainComponent>
      <Header
        title="Nova Ordem de Serviço"
        page={2}
        setPage={setPage}
        onClick={() => {
          handleSave();
          setPage(1);
        }}
      />
      <div className={styles.content}>
        <ProgressBar
          currentPage={2}
          pages={['Veículos', 'Clientes', 'Serviços']}
        />

        <Box>
          <AlertModal
            content="Seu cliente será notificado que a ordem de serviço atual foi finalizada"
            isOpen={showModal}
            setIsOpen={setShowModal}
            onConfirm={() => handleSubmit()}
            onCancel={() => setStatus('0')}
          />
          <Modal
            isOpen={showErrorModal}
            onClose={() => setShowErrorModal(false)}
            title="Erro Identificado!"
          >
            <p>{errorMessage}</p>
          </Modal>

          <AlertModal
            content="Deseja Salvar a OS em andamento?"
            isOpen={showAdviceModal}
            setIsOpen={setShowAdviceModal}
            advice={showAdviceModal}
            onConfirm={() => {
              setStatus('1');
              setShowAdviceModal(false);
            }}
            onCancel={() => setShowAdviceModal(false)}
          />
          <SendOSModal
            isOpen={isSendOSModalOpen}
            onConfirm={() => {
              removeObjectFromLocal('@local-order-service');
              navigate('/order-service-list');
              // navigate('/home');
            }}
            status={sendOSModalStatus}
            isEditing={
              !!localOrderServiceData &&
              localOrderServiceData.id_repair.length > 0
            }
          />
          <VisitorModal
            isOpen={isVisitorModalOpen}
            onClose={() => {
              setIsVisitorModalOpen(false);
            }}
          />

          <FormControl {...formControlProps}>
            <FormControl.Label
              _text={{
                color: COLORS.DARKGRAY,
                fontWeight: '600',
              }}
            >
              Defeitos
            </FormControl.Label>
            <Text fontSize="0.875rem">
              Esse campo contém os defeitos relatados pelo cliente
            </Text>
            <Controller
              control={control}
              render={({ field }) => (
                <TextArea
                  aria-label="t1"
                  numberOfLines={4}
                  h="8.2rem"
                  placeholder="Ex: Freios com defeito"
                  autoCompleteType={undefined}
                  borderRadius="9px"
                  fontSize="1rem"
                  {...field}
                />
              )}
              name="defect"
              defaultValue=""
            />
          </FormControl>
          <FormControl {...formControlProps}>
            <FormControl.Label
              _text={{
                color: COLORS.DARKGRAY,
                fontWeight: '600',
              }}
            >
              Diagnóstico
            </FormControl.Label>
            <Text fontSize="0.875rem">
              Esse campo contém o parecer da oficina mecânica
            </Text>

            <Controller
              control={control}
              render={({ field }) => (
                <TextArea
                  aria-label="t1"
                  numberOfLines={4}
                  h="8.2rem"
                  autoCompleteType={undefined}
                  borderRadius="9px"
                  fontSize="1rem"
                  {...field}
                />
              )}
              name="comment"
              defaultValue=""
            />
          </FormControl>
          <ChecklistForm
            isOpen={isCheckListOpen}
            toggleIsOpen={() => {
              setIsCheckListOpen(!isCheckListOpen);
            }}
            checklistData={checklistData}
            setChecklistData={setChecklistData}
            bannersToRemove={bannersToRemove}
            setBannersToRemove={setBannersToRemove}
            uploadErrors={uploadErrors}
          />

          <EditableTable
            services={services}
            setServices={setServices}
            setStatus={setStatus}
          />
          <Divider />
          <Box w="100%" m="auto" mt="2rem">
            <Row justifyContent="space-between" my="1rem">
              <Text color="gray.4" fontWeight="medium">
                Subtotal:
              </Text>
              <Text color="gray.4" fontWeight="medium">
                {subtotal.toLocaleString('pt-br', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </Text>
            </Row>
            <Row w="100%" justifyContent="space-between" my="1rem">
              <Text color="gray.4" fontWeight="medium">
                Desconto:
              </Text>
              <Text>
                <Input
                  value={`R$ ${discount.replace('.', ',')}`}
                  keyboardType="numeric"
                  onChangeText={handleDiscountChange}
                  textAlign="right"
                  placeholder="0"
                  fontSize="1rem"
                />
              </Text>
            </Row>
            <Row w="100%" justifyContent="space-between" my="1rem">
              <Text bold>Total:</Text>
              <Text color="green.1" bold>
                {(subtotal - parseFloat(discount || '0')).toLocaleString(
                  'pt-br',
                  {
                    style: 'currency',
                    currency: 'BRL',
                  },
                )}
              </Text>
            </Row>
          </Box>
          <Box mb="2rem">
            <Text color="gray.4" bold mb="1rem" mt="2rem">
              Status
            </Text>
            <Radio.Group
              accessibilityLabel="status"
              name="status"
              value={status}
              onChange={nextStatus => {
                setStatus(nextStatus);
              }}
              flexWrap="wrap"
            >
              <Flex
                w="100%"
                flexWrap="wrap"
                flexDir={{ base: 'column', xl: 'row' }}
                justifyContent={['center', 'space-around']}
                alignItems="center"
              >
                <Box
                  py="0.5rem"
                  px="1rem"
                  w={['70%', 'auto']}
                  my=".5rem"
                  borderColor={status === '1' ? COLORS.GREEN : '#D6D7D8'}
                  borderWidth="2"
                  borderRadius="9px"
                >
                  <Radio value="1" colorScheme="green">
                    <Text
                      fontWeight="semibold"
                      w="200px"
                      color={status === '1' ? COLORS.GREEN : '#4b4b4b'}
                    >
                      Em andamento
                    </Text>
                  </Radio>
                </Box>
                <Box
                  my=".5rem"
                  py="0.5rem"
                  w={['70%', 'auto']}
                  px="1rem"
                  borderColor={status === '2' ? COLORS.GREEN : '#D6D7D8'}
                  borderWidth="2"
                  borderRadius="9px"
                >
                  <Radio value="2" colorScheme="green">
                    <Text
                      w="200px"
                      fontWeight="semibold"
                      color={status === '2' ? COLORS.GREEN : '#4b4b4b'}
                    >
                      Finalizado
                    </Text>
                  </Radio>
                </Box>
              </Flex>
            </Radio.Group>
          </Box>
          <Row justifyContent="space-between">
            <Button
              _hover={{ bg: 'green.1', color: 'white' }}
              onPress={() => {
                handleSave();
                setPage(1);
              }}
              {...buttonProps}
            >
              <AiOutlineLeft size="2rem" />
            </Button>
          </Row>
        </Box>
      </div>
    </MainComponent>
  );
};
