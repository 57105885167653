import { Navigate, Route, Routes as Switch } from 'react-router-dom';

import ContactLandingPage from '../../pages/common/LandingPage/Pages/Contact';
import ServicesLandingPage from '../../pages/common/LandingPage/Pages/Services';
import RegisterLandingPage from '../../pages/common/LandingPage/Pages/Register';
import { LandingPage } from '../../pages/common/LandingPage/Pages';
import About from '../../pages/common/LandingPage/Pages/AboutUs';
import { Version } from '../../version';
import { Test } from '../../pages/common/Test';
import { SearchVehicle } from '../../pages/common/SearchVehicle';
import { BudgetReport } from '../../utils/pdf/Budget';
import { ChecklistReport } from '../../utils/pdf/ChecklistReport';

export const PublicRoutes = () => {
  return (
    <Switch>
      <Route path="/" element={<LandingPage />} />
      <Route path="/test" element={<Test />} />
      <Route path="/version" element={<Version />} />|
      <Route path="/landing-page" element={<Navigate to="/" />} />
      <Route path="/page-services" element={<ServicesLandingPage />} />
      <Route path="/page-contact" element={<ContactLandingPage />} />
      <Route path="/page-register" element={<RegisterLandingPage />} />
      <Route path="/page-aboutUs" element={<About />} />
      <Route path="/search-vehicle" element={<SearchVehicle />} />
      <Route path="/budget-report" element={<BudgetReport />} />
      <Route path="/budgets" element={<BudgetReport />} />
      <Route path="/checklist-report" element={<ChecklistReport />} />
    </Switch>
  );
};
